import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const UsersRoutes: RouteRecordRaw = {
  path: '/users',
  name: RouteName.Users,
  component: () => import('@/views/UsersContainer.vue'),
  meta: {
    title: 'Users',
    group: RouteName.Users,
    layout: Layout.Authenticated,
  },
};

export const UserRoutes: RouteRecordRaw = {
  path: '/users/:id',
  name: 'User',
  component: () => import('../views/User.vue'),
  redirect: { name: RouteName.UserOverview },
  meta: {
    group: RouteName.Users,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'overview',
      name: RouteName.UserOverview,
      component: () => import('../views/User/UserOverview.vue'),
      meta: {
        title: 'Overview',
      },
    },
    {
      path: 'kpis',
      name: RouteName.UserHealthKpis,
      component: () => import('../views/User/UserHealthKpis.vue'),
      redirect: { name: RouteName.UserHealthKpisUsage },
      children: [
        {
          path: 'usage',
          name: RouteName.UserHealthKpisUsage,
          component: () => import('../views/User/UserHealthKpiViews/UserHealthKpiUsageSuccess.vue'),
          meta: {
            title: 'Usage Success',
          },
        },
        {
          path: 'outcome',
          name: RouteName.UserHealthKpisOutcome,
          component: () => import('../views/User/UserHealthKpiViews/UserHealthKpiOutcomeSuccess.vue'),
          meta: {
            title: 'Outcome Success',
          },
        },
      ],
    },
    {
      path: 'all-data',
      name: RouteName.UserAllData,
      component: () => import('../views/User/AllData/UserAllData.vue'),
      redirect: { name: RouteName.UserFeatureData },
      children: [
        {
          path: 'feature',
          name: RouteName.UserFeatureData,
          component: () => import('../views/User/AllData/Feature.vue'),
          meta: {
            title: 'User Feature',
          },
        },
      ],
    },
    {
      path: 'task',
      name: RouteName.UserTask,
      component: () => import('@/views/User/TaskContainer.vue'),
      meta: {
        title: 'Task',
      },
    },
    {
      path: 'message',
      name: RouteName.UserMessage,
      component: () => import('@/views/User/MessageContainer.vue'),
      meta: {
        title: 'Message',
      },
    },
    {
      path: 'action',
      name: RouteName.UserAction,
      component: () => import('@/views/User/ActionContainer.vue'),
      meta: {
        title: 'Action',
      },
    },
  ],
};
