<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { phCheck } from '@/phosphor-icons';
import { IDate } from '@/types/date.type';
import { getYear } from 'date-fns';
import { DateTypeEnum } from '@/api/client';
import { useDateFormatter } from '@/composables/useDateFormatter';

// TODO #frmGourav need to revisit this section once year aggregation is available
const props = defineProps<{ years: number[]; currentYear: number; selectedDate: IDate }>();
const selection = ref<number>();
const emits = defineEmits(['onDateSelection']);

const { getIDateYearObj } = useDateFormatter();

const yearList = computed(() => {
  const currentDate = new Date();
  const currentYear = getYear(currentDate);
  return props.years.filter((x) => x !== currentYear);
});

function onItemClick(year: number) {
  const startDate = new Date(`${year}-1-1`);
  const date = getIDateYearObj(startDate);
  selection.value = year;
  emits('onDateSelection', date);
}

const currentYear = computed(() => {
  const currentDate = new Date();
  const currentYear = getYear(currentDate);
  return currentYear;
});

watch(
  () => props.selectedDate,
  (newVal: IDate) => {
    if (newVal.type === DateTypeEnum.Year) {
      selection.value = getYear(newVal.startDate);
    }
  },
  { immediate: true },
);
</script>
<template>
  <div>
    <q-scroll-area style="height: 14rem" class="tw-h-full tw-w-full">
      <q-list>
        <q-item
          v-for="year in yearList"
          :key="year"
          clickable
          :active="selection === year"
          :disable="year === currentYear"
          @click="onItemClick(year)"
          active-class="my-menu-link"
        >
          <q-item-section>
            {{ year }}
          </q-item-section>
          <q-item-section side v-if="selection === year">
            <q-icon :name="phCheck" class="tw-text-light-shade-1" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>
<style scoped lang="postcss">
.my-menu-link {
  @apply tw-bg-primary-dark tw-text-light-shade-1;
}
q-item__section--side {
  @apply tw-text-light-shade-1;
}
</style>
