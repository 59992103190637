<script setup lang="ts">
import { computed, ref } from 'vue';
import { phCheck } from '@/phosphor-icons';
import { addQuarters, endOfYear, getYear } from 'date-fns';
import { IDate } from '@/types/date.type';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { useAccountStore } from '@/stores/account';
import { storeToRefs } from 'pinia';

const props = defineProps<{ selectedYear: number; selectedDate: IDate }>();
const emits = defineEmits(['onDateSelection']);

const { getIDateQuarterObj } = useDateFormatter();
const { onboardingCompletedAt } = storeToRefs(useAccountStore());

const selection = ref<IDate>(props.selectedDate);
// const quarters = ref<IDate[]>([]);

const quarters = computed(() => {
  const items = [] as IDate[];
  const currentDate = new Date();
  const currentYear = getYear(currentDate);
  let startDate = new Date(props.selectedYear, 0, 1);
  if (onboardingCompletedAt.value && onboardingCompletedAt.value.getFullYear() === props.selectedYear) {
    startDate = onboardingCompletedAt.value;
  }
  const endDate = endOfYear(startDate);
  while (startDate < endDate) {
    const item = getIDateQuarterObj(startDate);
    item.isDisabled = props.selectedYear === currentYear ? item.endDate > currentDate : false;
    items.push(item);
    startDate = addQuarters(startDate, 1);
  }
  return items;
});

function isSelected(quarter: IDate) {
  const selected = selection.value;
  return selected.endDate?.toString() === quarter.endDate?.toString() && quarter.type === selected.type;
}

function onItemClick(quarter: IDate) {
  selection.value = quarter;
  emits('onDateSelection', quarter);
}
</script>
<template>
  <q-list bordered padding class="rounded-borders text-primary tw-mx-5 tw-my-4 tw-bg-light-shade-1 tw-py-0">
    <q-item
      v-for="quarter in quarters"
      :key="quarter.startDate.toString()"
      clickable
      :active="isSelected(quarter)"
      @click="onItemClick(quarter)"
      active-class="my-menu-link"
      :disable="quarter.isDisabled"
    >
      <q-item-section>{{ quarter.text }}</q-item-section>
      <q-item-section side v-if="isSelected(quarter)">
        <q-icon :name="phCheck" class="tw-text-light-shade-1" />
      </q-item-section>
    </q-item>
  </q-list>
</template>
<style scoped lang="postcss">
.my-menu-link {
  @apply tw-bg-primary-dark tw-text-light-shade-1;
}
q-item__section--side {
  @apply tw-text-light-shade-1;
}
</style>
