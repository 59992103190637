/* tslint:disable */
/* eslint-disable */
export const FeatureFlagNames = {
    inbox: "N2P3KdKykhibMUB-5JFj4",
    u2_persona: "JLXtb2V1i_UrZm0QtFhOE",
    campaign: "IlYr2kOuJF_K-wQH-gC7a",
    segment_view: "Lr89SE1NWBLQ5u0G5FmUG",
    u2_emails_column: "0HsdNnwlbez6XrSxzNQIc",
    custom_field_type_edit: "FAx9TVFnNKgf-M_ZRK2v6",
    transformation_sync_settings: "zqXKCFSftit1srnIbLX-8",
    upsell_renewal: "rS1h4SK8Tx0imUg_cVW6l",
    segment_share: "n098c93fUoBkkAtLbAN6I",
    task_assignee_selection: "tOOhpMXNDXJwrwgF_8D4X",
    inbox_genai: "7De2IEWVjuIZEHuyOpBwr",
    customers_filter_V2: "ba-nxIyAtXz6vDM8ovMBq",
    bi_directional_notes: "SCLwzVrvNpqyT3BnPCc5C",
    survey: "Bb5eMXD_ZmyG6z3HpIj8u",
    task_genai: "zpRgzkO2NQy_AU-eLv4QO",
    message_genai: "byN7Bc88-92IPrI4JlDVz",
    action_genai: "YSib82wrshTDn_9iOyxnV",
    playbook_time_bound_condition: "4xp9X1HjND3gTV-eDFriM"
};