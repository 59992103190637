/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiKeyBasedAuthenticationDto } from '../model/';
// @ts-ignore
import { AutoIntegrationDto } from '../model/';
// @ts-ignore
import { BasicAuthBasedAuthenticationDto } from '../model/';
// @ts-ignore
import { CreatePeripheralAppDto } from '../model/';
// @ts-ignore
import { OAuthAuthenticationDto } from '../model/';
// @ts-ignore
import { PeripheralApp } from '../model/';
// @ts-ignore
import { PeripheralAppProjectsDto } from '../model/';
// @ts-ignore
import { ProjectsDto } from '../model/';
// @ts-ignore
import { UpdateDataWareHouseConfigurationDto } from '../model/';
// @ts-ignore
import { UpdateOptionalAppObjectsDto } from '../model/';
// @ts-ignore
import { UpdatePeripheralAppDto } from '../model/';
// @ts-ignore
import { UpdatePeripheralOrgIdDto } from '../model/';
// @ts-ignore
import { UpdatePeripheralPullDurationDto } from '../model/';
/**
 * PeripheralAppApi - axios parameter creator
 * @export
 */
export const PeripheralAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Amplitude Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerAmplitudeIncrementalEvents: async (key: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('peripheralAppsControllerAmplitudeIncrementalEvents', 'key', key)
            const localVarPath = `/api/peripheral-apps/amplitude-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Auto integrate linked apps
         * @param {AutoIntegrationDto} autoIntegrationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerAutoIntegrateLinkedApps: async (autoIntegrationDto: AutoIntegrationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'autoIntegrationDto' is not null or undefined
            assertParamExists('peripheralAppsControllerAutoIntegrateLinkedApps', 'autoIntegrationDto', autoIntegrationDto)
            const localVarPath = `/api/peripheral-apps/auto-integrate-linked-apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(autoIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Chargebee Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerChargebeeIncrementalEvents: async (key: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('peripheralAppsControllerChargebeeIncrementalEvents', 'key', key)
            const localVarPath = `/api/peripheral-apps/cb-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check whether U1 integrated Gmail or Outlook
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerCheckU1IntegratedGmailOrOutlook: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/peripheral-apps/integration-gmail-or-outlook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Peripheral App
         * @param {CreatePeripheralAppDto} createPeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerCreate: async (createPeripheralAppDto: CreatePeripheralAppDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPeripheralAppDto' is not null or undefined
            assertParamExists('peripheralAppsControllerCreate', 'createPeripheralAppDto', createPeripheralAppDto)
            const localVarPath = `/api/peripheral-apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPeripheralAppDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Peripheral Apps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/peripheral-apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Peripheral Apps with available data
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAllIntegratedApps: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerFindAllIntegratedApps', 'id', id)
            const localVarPath = `/api/peripheral-apps/peripheral-apps-available-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral app list with sync date
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAllWithSyncDate: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/peripheral-apps/apps-with-sync-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get linked apps for auto integration
         * @param {Array<string>} linkedApps 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAppsForAutoIntegration: async (linkedApps: Array<string>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkedApps' is not null or undefined
            assertParamExists('peripheralAppsControllerFindAppsForAutoIntegration', 'linkedApps', linkedApps)
            const localVarPath = `/api/peripheral-apps/find-apps-for-auto-integration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (linkedApps) {
                localVarQueryParameter['linked_apps'] = linkedApps;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Peripheral App by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerFindOne', 'id', id)
            const localVarPath = `/api/peripheral-apps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get project from peripheral app
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindProject: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerFindProject', 'id', id)
            const localVarPath = `/api/peripheral-apps/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Peripheral App
         * @param {string} id 
         * @param {UpdatePeripheralAppDto} updatePeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerIntegrate: async (id: string, updatePeripheralAppDto: UpdatePeripheralAppDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerIntegrate', 'id', id)
            // verify required parameter 'updatePeripheralAppDto' is not null or undefined
            assertParamExists('peripheralAppsControllerIntegrate', 'updatePeripheralAppDto', updatePeripheralAppDto)
            const localVarPath = `/api/peripheral-apps/{id}/integrate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePeripheralAppDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Integrate data warehouse configuration with configuration
         * @param {string} id 
         * @param {UpdateDataWareHouseConfigurationDto} updateDataWareHouseConfigurationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerIntegrateDataWareHouseConfiguration: async (id: string, updateDataWareHouseConfigurationDto: UpdateDataWareHouseConfigurationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerIntegrateDataWareHouseConfiguration', 'id', id)
            // verify required parameter 'updateDataWareHouseConfigurationDto' is not null or undefined
            assertParamExists('peripheralAppsControllerIntegrateDataWareHouseConfiguration', 'updateDataWareHouseConfigurationDto', updateDataWareHouseConfigurationDto)
            const localVarPath = `/api/peripheral-apps/integrate-data-warehouse-app/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataWareHouseConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral ApiKey Authentication
         * @param {ApiKeyBasedAuthenticationDto} apiKeyBasedAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerPeripheralApiKeyAuthentication: async (apiKeyBasedAuthenticationDto: ApiKeyBasedAuthenticationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyBasedAuthenticationDto' is not null or undefined
            assertParamExists('peripheralAppsControllerPeripheralApiKeyAuthentication', 'apiKeyBasedAuthenticationDto', apiKeyBasedAuthenticationDto)
            const localVarPath = `/api/peripheral-apps/peripheral-api-key-authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiKeyBasedAuthenticationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral Basic Auth Authentication
         * @param {BasicAuthBasedAuthenticationDto} basicAuthBasedAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerPeripheralBasicAuthAuthentication: async (basicAuthBasedAuthenticationDto: BasicAuthBasedAuthenticationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'basicAuthBasedAuthenticationDto' is not null or undefined
            assertParamExists('peripheralAppsControllerPeripheralBasicAuthAuthentication', 'basicAuthBasedAuthenticationDto', basicAuthBasedAuthenticationDto)
            const localVarPath = `/api/peripheral-apps/peripheral-basic-auth-authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicAuthBasedAuthenticationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral OAuth Authentication
         * @param {OAuthAuthenticationDto} oAuthAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerPeripheralOauthAuthentication: async (oAuthAuthenticationDto: OAuthAuthenticationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthAuthenticationDto' is not null or undefined
            assertParamExists('peripheralAppsControllerPeripheralOauthAuthentication', 'oAuthAuthenticationDto', oAuthAuthenticationDto)
            const localVarPath = `/api/peripheral-apps/peripheral-oauth-authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthAuthenticationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Peripheral App
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerRemove', 'id', id)
            const localVarPath = `/api/peripheral-apps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset All Peripheral Integrations
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerResetAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/peripheral-apps/reset-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Segment Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSegmentIncrementalEvents: async (key: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('peripheralAppsControllerSegmentIncrementalEvents', 'key', key)
            const localVarPath = `/api/peripheral-apps/segment-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set a Peripheral App Data Pull Duration
         * @param {UpdatePeripheralPullDurationDto} updatePeripheralPullDurationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSetDataPullDuration: async (updatePeripheralPullDurationDto: UpdatePeripheralPullDurationDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePeripheralPullDurationDto' is not null or undefined
            assertParamExists('peripheralAppsControllerSetDataPullDuration', 'updatePeripheralPullDurationDto', updatePeripheralPullDurationDto)
            const localVarPath = `/api/peripheral-apps/data-pull-duration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePeripheralPullDurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set optional app objects
         * @param {UpdateOptionalAppObjectsDto} updateOptionalAppObjectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSetOptionalAppObjects: async (updateOptionalAppObjectsDto: UpdateOptionalAppObjectsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOptionalAppObjectsDto' is not null or undefined
            assertParamExists('peripheralAppsControllerSetOptionalAppObjects', 'updateOptionalAppObjectsDto', updateOptionalAppObjectsDto)
            const localVarPath = `/api/peripheral-apps/optional-app-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOptionalAppObjectsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set a Peripheral App Organization ID
         * @param {UpdatePeripheralOrgIdDto} updatePeripheralOrgIdDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSetOrganizationId: async (updatePeripheralOrgIdDto: UpdatePeripheralOrgIdDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePeripheralOrgIdDto' is not null or undefined
            assertParamExists('peripheralAppsControllerSetOrganizationId', 'updatePeripheralOrgIdDto', updatePeripheralOrgIdDto)
            const localVarPath = `/api/peripheral-apps/organization-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePeripheralOrgIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stripe As Product Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerStripeAsPricingIncrementalEvents: async (key: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('peripheralAppsControllerStripeAsPricingIncrementalEvents', 'key', key)
            const localVarPath = `/api/peripheral-apps/stripe-pricing-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stripe As Product Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerStripeAsProductIncrementalEvents: async (key: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('peripheralAppsControllerStripeAsProductIncrementalEvents', 'key', key)
            const localVarPath = `/api/peripheral-apps/stripe-product-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Peripheral App
         * @param {string} id 
         * @param {UpdatePeripheralAppDto} updatePeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerUpdate: async (id: string, updatePeripheralAppDto: UpdatePeripheralAppDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerUpdate', 'id', id)
            // verify required parameter 'updatePeripheralAppDto' is not null or undefined
            assertParamExists('peripheralAppsControllerUpdate', 'updatePeripheralAppDto', updatePeripheralAppDto)
            const localVarPath = `/api/peripheral-apps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePeripheralAppDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add project data to peripheral app collection
         * @param {string} id 
         * @param {ProjectsDto} projectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerUpdateProjectData: async (id: string, projectsDto: ProjectsDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAppsControllerUpdateProjectData', 'id', id)
            // verify required parameter 'projectsDto' is not null or undefined
            assertParamExists('peripheralAppsControllerUpdateProjectData', 'projectsDto', projectsDto)
            const localVarPath = `/api/peripheral-apps/update-project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeripheralAppApi - functional programming interface
 * @export
 */
export const PeripheralAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeripheralAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Amplitude Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerAmplitudeIncrementalEvents(key: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerAmplitudeIncrementalEvents(key, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Auto integrate linked apps
         * @param {AutoIntegrationDto} autoIntegrationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerAutoIntegrateLinkedApps(autoIntegrationDto: AutoIntegrationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerAutoIntegrateLinkedApps(autoIntegrationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Chargebee Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerChargebeeIncrementalEvents(key: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerChargebeeIncrementalEvents(key, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check whether U1 integrated Gmail or Outlook
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerCheckU1IntegratedGmailOrOutlook(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerCheckU1IntegratedGmailOrOutlook(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Peripheral App
         * @param {CreatePeripheralAppDto} createPeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerCreate(createPeripheralAppDto: CreatePeripheralAppDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerCreate(createPeripheralAppDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Peripheral Apps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Peripheral Apps with available data
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerFindAllIntegratedApps(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerFindAllIntegratedApps(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral app list with sync date
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerFindAllWithSyncDate(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerFindAllWithSyncDate(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get linked apps for auto integration
         * @param {Array<string>} linkedApps 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerFindAppsForAutoIntegration(linkedApps: Array<string>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerFindAppsForAutoIntegration(linkedApps, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Peripheral App by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get project from peripheral app
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerFindProject(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralAppProjectsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerFindProject(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Peripheral App
         * @param {string} id 
         * @param {UpdatePeripheralAppDto} updatePeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerIntegrate(id: string, updatePeripheralAppDto: UpdatePeripheralAppDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerIntegrate(id, updatePeripheralAppDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Integrate data warehouse configuration with configuration
         * @param {string} id 
         * @param {UpdateDataWareHouseConfigurationDto} updateDataWareHouseConfigurationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerIntegrateDataWareHouseConfiguration(id: string, updateDataWareHouseConfigurationDto: UpdateDataWareHouseConfigurationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerIntegrateDataWareHouseConfiguration(id, updateDataWareHouseConfigurationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral ApiKey Authentication
         * @param {ApiKeyBasedAuthenticationDto} apiKeyBasedAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerPeripheralApiKeyAuthentication(apiKeyBasedAuthenticationDto: ApiKeyBasedAuthenticationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerPeripheralApiKeyAuthentication(apiKeyBasedAuthenticationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral Basic Auth Authentication
         * @param {BasicAuthBasedAuthenticationDto} basicAuthBasedAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerPeripheralBasicAuthAuthentication(basicAuthBasedAuthenticationDto: BasicAuthBasedAuthenticationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerPeripheralBasicAuthAuthentication(basicAuthBasedAuthenticationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral OAuth Authentication
         * @param {OAuthAuthenticationDto} oAuthAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerPeripheralOauthAuthentication(oAuthAuthenticationDto: OAuthAuthenticationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerPeripheralOauthAuthentication(oAuthAuthenticationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Peripheral App
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset All Peripheral Integrations
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerResetAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerResetAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Segment Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerSegmentIncrementalEvents(key: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerSegmentIncrementalEvents(key, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set a Peripheral App Data Pull Duration
         * @param {UpdatePeripheralPullDurationDto} updatePeripheralPullDurationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerSetDataPullDuration(updatePeripheralPullDurationDto: UpdatePeripheralPullDurationDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerSetDataPullDuration(updatePeripheralPullDurationDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set optional app objects
         * @param {UpdateOptionalAppObjectsDto} updateOptionalAppObjectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerSetOptionalAppObjects(updateOptionalAppObjectsDto: UpdateOptionalAppObjectsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerSetOptionalAppObjects(updateOptionalAppObjectsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set a Peripheral App Organization ID
         * @param {UpdatePeripheralOrgIdDto} updatePeripheralOrgIdDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerSetOrganizationId(updatePeripheralOrgIdDto: UpdatePeripheralOrgIdDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerSetOrganizationId(updatePeripheralOrgIdDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stripe As Product Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerStripeAsPricingIncrementalEvents(key: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerStripeAsPricingIncrementalEvents(key, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stripe As Product Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerStripeAsProductIncrementalEvents(key: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerStripeAsProductIncrementalEvents(key, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Peripheral App
         * @param {string} id 
         * @param {UpdatePeripheralAppDto} updatePeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerUpdate(id: string, updatePeripheralAppDto: UpdatePeripheralAppDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerUpdate(id, updatePeripheralAppDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add project data to peripheral app collection
         * @param {string} id 
         * @param {ProjectsDto} projectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAppsControllerUpdateProjectData(id: string, projectsDto: ProjectsDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAppsControllerUpdateProjectData(id, projectsDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeripheralAppApi - factory interface
 * @export
 */
export const PeripheralAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeripheralAppApiFp(configuration)
    return {
        /**
         * 
         * @summary Amplitude Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerAmplitudeIncrementalEvents(key: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.peripheralAppsControllerAmplitudeIncrementalEvents(key, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Auto integrate linked apps
         * @param {AutoIntegrationDto} autoIntegrationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerAutoIntegrateLinkedApps(autoIntegrationDto: AutoIntegrationDto, xTenantId?: string, options?: any): AxiosPromise<Array<PeripheralApp>> {
            return localVarFp.peripheralAppsControllerAutoIntegrateLinkedApps(autoIntegrationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Chargebee Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerChargebeeIncrementalEvents(key: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.peripheralAppsControllerChargebeeIncrementalEvents(key, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check whether U1 integrated Gmail or Outlook
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerCheckU1IntegratedGmailOrOutlook(xTenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.peripheralAppsControllerCheckU1IntegratedGmailOrOutlook(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Peripheral App
         * @param {CreatePeripheralAppDto} createPeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerCreate(createPeripheralAppDto: CreatePeripheralAppDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerCreate(createPeripheralAppDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Peripheral Apps
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<PeripheralApp>> {
            return localVarFp.peripheralAppsControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Peripheral Apps with available data
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAllIntegratedApps(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<PeripheralApp>> {
            return localVarFp.peripheralAppsControllerFindAllIntegratedApps(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral app list with sync date
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAllWithSyncDate(xTenantId?: string, options?: any): AxiosPromise<Array<PeripheralApp>> {
            return localVarFp.peripheralAppsControllerFindAllWithSyncDate(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get linked apps for auto integration
         * @param {Array<string>} linkedApps 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindAppsForAutoIntegration(linkedApps: Array<string>, xTenantId?: string, options?: any): AxiosPromise<Array<PeripheralApp>> {
            return localVarFp.peripheralAppsControllerFindAppsForAutoIntegration(linkedApps, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Peripheral App by Id
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get project from peripheral app
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerFindProject(id: string, xTenantId?: string, options?: any): AxiosPromise<Array<PeripheralAppProjectsDto>> {
            return localVarFp.peripheralAppsControllerFindProject(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Peripheral App
         * @param {string} id 
         * @param {UpdatePeripheralAppDto} updatePeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerIntegrate(id: string, updatePeripheralAppDto: UpdatePeripheralAppDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerIntegrate(id, updatePeripheralAppDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Integrate data warehouse configuration with configuration
         * @param {string} id 
         * @param {UpdateDataWareHouseConfigurationDto} updateDataWareHouseConfigurationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerIntegrateDataWareHouseConfiguration(id: string, updateDataWareHouseConfigurationDto: UpdateDataWareHouseConfigurationDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerIntegrateDataWareHouseConfiguration(id, updateDataWareHouseConfigurationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral ApiKey Authentication
         * @param {ApiKeyBasedAuthenticationDto} apiKeyBasedAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerPeripheralApiKeyAuthentication(apiKeyBasedAuthenticationDto: ApiKeyBasedAuthenticationDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerPeripheralApiKeyAuthentication(apiKeyBasedAuthenticationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral Basic Auth Authentication
         * @param {BasicAuthBasedAuthenticationDto} basicAuthBasedAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerPeripheralBasicAuthAuthentication(basicAuthBasedAuthenticationDto: BasicAuthBasedAuthenticationDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerPeripheralBasicAuthAuthentication(basicAuthBasedAuthenticationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral OAuth Authentication
         * @param {OAuthAuthenticationDto} oAuthAuthenticationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerPeripheralOauthAuthentication(oAuthAuthenticationDto: OAuthAuthenticationDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerPeripheralOauthAuthentication(oAuthAuthenticationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Peripheral App
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.peripheralAppsControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset All Peripheral Integrations
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerResetAll(xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.peripheralAppsControllerResetAll(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Segment Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSegmentIncrementalEvents(key: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.peripheralAppsControllerSegmentIncrementalEvents(key, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set a Peripheral App Data Pull Duration
         * @param {UpdatePeripheralPullDurationDto} updatePeripheralPullDurationDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSetDataPullDuration(updatePeripheralPullDurationDto: UpdatePeripheralPullDurationDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerSetDataPullDuration(updatePeripheralPullDurationDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set optional app objects
         * @param {UpdateOptionalAppObjectsDto} updateOptionalAppObjectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSetOptionalAppObjects(updateOptionalAppObjectsDto: UpdateOptionalAppObjectsDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerSetOptionalAppObjects(updateOptionalAppObjectsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set a Peripheral App Organization ID
         * @param {UpdatePeripheralOrgIdDto} updatePeripheralOrgIdDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerSetOrganizationId(updatePeripheralOrgIdDto: UpdatePeripheralOrgIdDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerSetOrganizationId(updatePeripheralOrgIdDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stripe As Product Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerStripeAsPricingIncrementalEvents(key: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.peripheralAppsControllerStripeAsPricingIncrementalEvents(key, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stripe As Product Webhook
         * @param {string} key 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerStripeAsProductIncrementalEvents(key: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.peripheralAppsControllerStripeAsProductIncrementalEvents(key, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Peripheral App
         * @param {string} id 
         * @param {UpdatePeripheralAppDto} updatePeripheralAppDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerUpdate(id: string, updatePeripheralAppDto: UpdatePeripheralAppDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerUpdate(id, updatePeripheralAppDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add project data to peripheral app collection
         * @param {string} id 
         * @param {ProjectsDto} projectsDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAppsControllerUpdateProjectData(id: string, projectsDto: ProjectsDto, xTenantId?: string, options?: any): AxiosPromise<PeripheralApp> {
            return localVarFp.peripheralAppsControllerUpdateProjectData(id, projectsDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for peripheralAppsControllerAmplitudeIncrementalEvents operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerAmplitudeIncrementalEventsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerAmplitudeIncrementalEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerAmplitudeIncrementalEvents
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerAmplitudeIncrementalEvents
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerAutoIntegrateLinkedApps operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerAutoIntegrateLinkedAppsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerAutoIntegrateLinkedAppsRequest {
    /**
     * 
     * @type {AutoIntegrationDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerAutoIntegrateLinkedApps
     */
    readonly autoIntegrationDto: AutoIntegrationDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerAutoIntegrateLinkedApps
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerChargebeeIncrementalEvents operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerChargebeeIncrementalEventsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerChargebeeIncrementalEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerChargebeeIncrementalEvents
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerChargebeeIncrementalEvents
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerCheckU1IntegratedGmailOrOutlook operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerCheckU1IntegratedGmailOrOutlookRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerCheckU1IntegratedGmailOrOutlookRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerCheckU1IntegratedGmailOrOutlook
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerCreate operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerCreateRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerCreateRequest {
    /**
     * 
     * @type {CreatePeripheralAppDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerCreate
     */
    readonly createPeripheralAppDto: CreatePeripheralAppDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerFindAll operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerFindAllRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerFindAllIntegratedApps operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerFindAllIntegratedAppsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerFindAllIntegratedAppsRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindAllIntegratedApps
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindAllIntegratedApps
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerFindAllWithSyncDate operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerFindAllWithSyncDateRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerFindAllWithSyncDateRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindAllWithSyncDate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerFindAppsForAutoIntegration operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerFindAppsForAutoIntegrationRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerFindAppsForAutoIntegrationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindAppsForAutoIntegration
     */
    readonly linkedApps: Array<string>

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindAppsForAutoIntegration
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerFindOne operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerFindOneRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerFindProject operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerFindProjectRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerFindProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindProject
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerFindProject
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerIntegrate operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerIntegrateRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerIntegrateRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerIntegrate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePeripheralAppDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerIntegrate
     */
    readonly updatePeripheralAppDto: UpdatePeripheralAppDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerIntegrate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerIntegrateDataWareHouseConfiguration operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfigurationRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfiguration
     */
    readonly id: string

    /**
     * 
     * @type {UpdateDataWareHouseConfigurationDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfiguration
     */
    readonly updateDataWareHouseConfigurationDto: UpdateDataWareHouseConfigurationDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfiguration
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerPeripheralApiKeyAuthentication operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerPeripheralApiKeyAuthenticationRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerPeripheralApiKeyAuthenticationRequest {
    /**
     * 
     * @type {ApiKeyBasedAuthenticationDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerPeripheralApiKeyAuthentication
     */
    readonly apiKeyBasedAuthenticationDto: ApiKeyBasedAuthenticationDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerPeripheralApiKeyAuthentication
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerPeripheralBasicAuthAuthentication operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerPeripheralBasicAuthAuthenticationRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerPeripheralBasicAuthAuthenticationRequest {
    /**
     * 
     * @type {BasicAuthBasedAuthenticationDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerPeripheralBasicAuthAuthentication
     */
    readonly basicAuthBasedAuthenticationDto: BasicAuthBasedAuthenticationDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerPeripheralBasicAuthAuthentication
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerPeripheralOauthAuthentication operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerPeripheralOauthAuthenticationRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerPeripheralOauthAuthenticationRequest {
    /**
     * 
     * @type {OAuthAuthenticationDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerPeripheralOauthAuthentication
     */
    readonly oAuthAuthenticationDto: OAuthAuthenticationDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerPeripheralOauthAuthentication
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerRemove operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerRemoveRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerResetAll operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerResetAllRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerResetAllRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerResetAll
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerSegmentIncrementalEvents operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerSegmentIncrementalEventsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerSegmentIncrementalEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerSegmentIncrementalEvents
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerSegmentIncrementalEvents
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerSetDataPullDuration operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerSetDataPullDurationRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerSetDataPullDurationRequest {
    /**
     * 
     * @type {UpdatePeripheralPullDurationDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerSetDataPullDuration
     */
    readonly updatePeripheralPullDurationDto: UpdatePeripheralPullDurationDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerSetDataPullDuration
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerSetOptionalAppObjects operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerSetOptionalAppObjectsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerSetOptionalAppObjectsRequest {
    /**
     * 
     * @type {UpdateOptionalAppObjectsDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerSetOptionalAppObjects
     */
    readonly updateOptionalAppObjectsDto: UpdateOptionalAppObjectsDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerSetOptionalAppObjects
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerSetOrganizationId operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerSetOrganizationIdRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerSetOrganizationIdRequest {
    /**
     * 
     * @type {UpdatePeripheralOrgIdDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerSetOrganizationId
     */
    readonly updatePeripheralOrgIdDto: UpdatePeripheralOrgIdDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerSetOrganizationId
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerStripeAsPricingIncrementalEvents operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerStripeAsPricingIncrementalEventsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerStripeAsPricingIncrementalEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerStripeAsPricingIncrementalEvents
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerStripeAsPricingIncrementalEvents
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerStripeAsProductIncrementalEvents operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerStripeAsProductIncrementalEventsRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerStripeAsProductIncrementalEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerStripeAsProductIncrementalEvents
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerStripeAsProductIncrementalEvents
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerUpdate operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerUpdateRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdatePeripheralAppDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerUpdate
     */
    readonly updatePeripheralAppDto: UpdatePeripheralAppDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for peripheralAppsControllerUpdateProjectData operation in PeripheralAppApi.
 * @export
 * @interface PeripheralAppApiPeripheralAppsControllerUpdateProjectDataRequest
 */
export interface PeripheralAppApiPeripheralAppsControllerUpdateProjectDataRequest {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerUpdateProjectData
     */
    readonly id: string

    /**
     * 
     * @type {ProjectsDto}
     * @memberof PeripheralAppApiPeripheralAppsControllerUpdateProjectData
     */
    readonly projectsDto: ProjectsDto

    /**
     * 
     * @type {string}
     * @memberof PeripheralAppApiPeripheralAppsControllerUpdateProjectData
     */
    readonly xTenantId?: string
}

/**
 * PeripheralAppApi - object-oriented interface
 * @export
 * @class PeripheralAppApi
 * @extends {BaseAPI}
 */
export class PeripheralAppApi extends BaseAPI {
    /**
     * 
     * @summary Amplitude Webhook
     * @param {PeripheralAppApiPeripheralAppsControllerAmplitudeIncrementalEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerAmplitudeIncrementalEvents(requestParameters: PeripheralAppApiPeripheralAppsControllerAmplitudeIncrementalEventsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerAmplitudeIncrementalEvents(requestParameters.key, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Auto integrate linked apps
     * @param {PeripheralAppApiPeripheralAppsControllerAutoIntegrateLinkedAppsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerAutoIntegrateLinkedApps(requestParameters: PeripheralAppApiPeripheralAppsControllerAutoIntegrateLinkedAppsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerAutoIntegrateLinkedApps(requestParameters.autoIntegrationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Chargebee Webhook
     * @param {PeripheralAppApiPeripheralAppsControllerChargebeeIncrementalEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerChargebeeIncrementalEvents(requestParameters: PeripheralAppApiPeripheralAppsControllerChargebeeIncrementalEventsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerChargebeeIncrementalEvents(requestParameters.key, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check whether U1 integrated Gmail or Outlook
     * @param {PeripheralAppApiPeripheralAppsControllerCheckU1IntegratedGmailOrOutlookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerCheckU1IntegratedGmailOrOutlook(requestParameters: PeripheralAppApiPeripheralAppsControllerCheckU1IntegratedGmailOrOutlookRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerCheckU1IntegratedGmailOrOutlook(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Peripheral App
     * @param {PeripheralAppApiPeripheralAppsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerCreate(requestParameters: PeripheralAppApiPeripheralAppsControllerCreateRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerCreate(requestParameters.createPeripheralAppDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Peripheral Apps
     * @param {PeripheralAppApiPeripheralAppsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerFindAll(requestParameters: PeripheralAppApiPeripheralAppsControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Peripheral Apps with available data
     * @param {PeripheralAppApiPeripheralAppsControllerFindAllIntegratedAppsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerFindAllIntegratedApps(requestParameters: PeripheralAppApiPeripheralAppsControllerFindAllIntegratedAppsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerFindAllIntegratedApps(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral app list with sync date
     * @param {PeripheralAppApiPeripheralAppsControllerFindAllWithSyncDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerFindAllWithSyncDate(requestParameters: PeripheralAppApiPeripheralAppsControllerFindAllWithSyncDateRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerFindAllWithSyncDate(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get linked apps for auto integration
     * @param {PeripheralAppApiPeripheralAppsControllerFindAppsForAutoIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerFindAppsForAutoIntegration(requestParameters: PeripheralAppApiPeripheralAppsControllerFindAppsForAutoIntegrationRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerFindAppsForAutoIntegration(requestParameters.linkedApps, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Peripheral App by Id
     * @param {PeripheralAppApiPeripheralAppsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerFindOne(requestParameters: PeripheralAppApiPeripheralAppsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get project from peripheral app
     * @param {PeripheralAppApiPeripheralAppsControllerFindProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerFindProject(requestParameters: PeripheralAppApiPeripheralAppsControllerFindProjectRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerFindProject(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Peripheral App
     * @param {PeripheralAppApiPeripheralAppsControllerIntegrateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerIntegrate(requestParameters: PeripheralAppApiPeripheralAppsControllerIntegrateRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerIntegrate(requestParameters.id, requestParameters.updatePeripheralAppDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Integrate data warehouse configuration with configuration
     * @param {PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerIntegrateDataWareHouseConfiguration(requestParameters: PeripheralAppApiPeripheralAppsControllerIntegrateDataWareHouseConfigurationRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerIntegrateDataWareHouseConfiguration(requestParameters.id, requestParameters.updateDataWareHouseConfigurationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral ApiKey Authentication
     * @param {PeripheralAppApiPeripheralAppsControllerPeripheralApiKeyAuthenticationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerPeripheralApiKeyAuthentication(requestParameters: PeripheralAppApiPeripheralAppsControllerPeripheralApiKeyAuthenticationRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerPeripheralApiKeyAuthentication(requestParameters.apiKeyBasedAuthenticationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral Basic Auth Authentication
     * @param {PeripheralAppApiPeripheralAppsControllerPeripheralBasicAuthAuthenticationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerPeripheralBasicAuthAuthentication(requestParameters: PeripheralAppApiPeripheralAppsControllerPeripheralBasicAuthAuthenticationRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerPeripheralBasicAuthAuthentication(requestParameters.basicAuthBasedAuthenticationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral OAuth Authentication
     * @param {PeripheralAppApiPeripheralAppsControllerPeripheralOauthAuthenticationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerPeripheralOauthAuthentication(requestParameters: PeripheralAppApiPeripheralAppsControllerPeripheralOauthAuthenticationRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerPeripheralOauthAuthentication(requestParameters.oAuthAuthenticationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Peripheral App
     * @param {PeripheralAppApiPeripheralAppsControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerRemove(requestParameters: PeripheralAppApiPeripheralAppsControllerRemoveRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset All Peripheral Integrations
     * @param {PeripheralAppApiPeripheralAppsControllerResetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerResetAll(requestParameters: PeripheralAppApiPeripheralAppsControllerResetAllRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerResetAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Segment Webhook
     * @param {PeripheralAppApiPeripheralAppsControllerSegmentIncrementalEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerSegmentIncrementalEvents(requestParameters: PeripheralAppApiPeripheralAppsControllerSegmentIncrementalEventsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerSegmentIncrementalEvents(requestParameters.key, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set a Peripheral App Data Pull Duration
     * @param {PeripheralAppApiPeripheralAppsControllerSetDataPullDurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerSetDataPullDuration(requestParameters: PeripheralAppApiPeripheralAppsControllerSetDataPullDurationRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerSetDataPullDuration(requestParameters.updatePeripheralPullDurationDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set optional app objects
     * @param {PeripheralAppApiPeripheralAppsControllerSetOptionalAppObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerSetOptionalAppObjects(requestParameters: PeripheralAppApiPeripheralAppsControllerSetOptionalAppObjectsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerSetOptionalAppObjects(requestParameters.updateOptionalAppObjectsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set a Peripheral App Organization ID
     * @param {PeripheralAppApiPeripheralAppsControllerSetOrganizationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerSetOrganizationId(requestParameters: PeripheralAppApiPeripheralAppsControllerSetOrganizationIdRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerSetOrganizationId(requestParameters.updatePeripheralOrgIdDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stripe As Product Webhook
     * @param {PeripheralAppApiPeripheralAppsControllerStripeAsPricingIncrementalEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerStripeAsPricingIncrementalEvents(requestParameters: PeripheralAppApiPeripheralAppsControllerStripeAsPricingIncrementalEventsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerStripeAsPricingIncrementalEvents(requestParameters.key, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stripe As Product Webhook
     * @param {PeripheralAppApiPeripheralAppsControllerStripeAsProductIncrementalEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerStripeAsProductIncrementalEvents(requestParameters: PeripheralAppApiPeripheralAppsControllerStripeAsProductIncrementalEventsRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerStripeAsProductIncrementalEvents(requestParameters.key, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Peripheral App
     * @param {PeripheralAppApiPeripheralAppsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerUpdate(requestParameters: PeripheralAppApiPeripheralAppsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerUpdate(requestParameters.id, requestParameters.updatePeripheralAppDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add project data to peripheral app collection
     * @param {PeripheralAppApiPeripheralAppsControllerUpdateProjectDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAppApi
     */
    public peripheralAppsControllerUpdateProjectData(requestParameters: PeripheralAppApiPeripheralAppsControllerUpdateProjectDataRequest, options?: AxiosRequestConfig) {
        return PeripheralAppApiFp(this.configuration).peripheralAppsControllerUpdateProjectData(requestParameters.id, requestParameters.projectsDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
