/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateZapscaleNoteDto } from '../model/';
// @ts-ignore
import { PaginatedZapscaleNoteDto } from '../model/';
// @ts-ignore
import { UpdateZapscaleNoteDto } from '../model/';
// @ts-ignore
import { ZapscaleNote } from '../model/';
// @ts-ignore
import { ZsNoteFilterDto } from '../model/';
/**
 * ZsNotesApi - axios parameter creator
 * @export
 */
export const ZsNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create zapscale note
         * @param {CreateZapscaleNoteDto} createZapscaleNoteDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerCreateZsNote: async (createZapscaleNoteDto: CreateZapscaleNoteDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createZapscaleNoteDto' is not null or undefined
            assertParamExists('zsNotesControllerCreateZsNote', 'createZapscaleNoteDto', createZapscaleNoteDto)
            const localVarPath = `/api/zs-notes/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createZapscaleNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete note against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerDeleteNote: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zsNotesControllerDeleteNote', 'id', id)
            const localVarPath = `/api/zs-notes/{id}/delete-note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all zapscale notes
         * @param {ZsNoteFilterDto} zsNoteFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerFilter: async (zsNoteFilterDto: ZsNoteFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zsNoteFilterDto' is not null or undefined
            assertParamExists('zsNotesControllerFilter', 'zsNoteFilterDto', zsNoteFilterDto)
            const localVarPath = `/api/zs-notes/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zsNoteFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all zapscale notes for a c2
         * @param {ZsNoteFilterDto} zsNoteFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerFilterPaginatedNotesForC2: async (zsNoteFilterDto: ZsNoteFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zsNoteFilterDto' is not null or undefined
            assertParamExists('zsNotesControllerFilterPaginatedNotesForC2', 'zsNoteFilterDto', zsNoteFilterDto)
            const localVarPath = `/api/zs-notes/c2-zs-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zsNoteFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update note against ID
         * @param {string} id 
         * @param {UpdateZapscaleNoteDto} updateZapscaleNoteDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerUpdateNote: async (id: string, updateZapscaleNoteDto: UpdateZapscaleNoteDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zsNotesControllerUpdateNote', 'id', id)
            // verify required parameter 'updateZapscaleNoteDto' is not null or undefined
            assertParamExists('zsNotesControllerUpdateNote', 'updateZapscaleNoteDto', updateZapscaleNoteDto)
            const localVarPath = `/api/zs-notes/{id}/update-note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateZapscaleNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZsNotesApi - functional programming interface
 * @export
 */
export const ZsNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZsNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create zapscale note
         * @param {CreateZapscaleNoteDto} createZapscaleNoteDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zsNotesControllerCreateZsNote(createZapscaleNoteDto: CreateZapscaleNoteDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zsNotesControllerCreateZsNote(createZapscaleNoteDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete note against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zsNotesControllerDeleteNote(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zsNotesControllerDeleteNote(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all zapscale notes
         * @param {ZsNoteFilterDto} zsNoteFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zsNotesControllerFilter(zsNoteFilterDto: ZsNoteFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedZapscaleNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zsNotesControllerFilter(zsNoteFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of all zapscale notes for a c2
         * @param {ZsNoteFilterDto} zsNoteFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zsNotesControllerFilterPaginatedNotesForC2(zsNoteFilterDto: ZsNoteFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedZapscaleNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zsNotesControllerFilterPaginatedNotesForC2(zsNoteFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update note against ID
         * @param {string} id 
         * @param {UpdateZapscaleNoteDto} updateZapscaleNoteDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zsNotesControllerUpdateNote(id: string, updateZapscaleNoteDto: UpdateZapscaleNoteDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZapscaleNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zsNotesControllerUpdateNote(id, updateZapscaleNoteDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZsNotesApi - factory interface
 * @export
 */
export const ZsNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZsNotesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create zapscale note
         * @param {CreateZapscaleNoteDto} createZapscaleNoteDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerCreateZsNote(createZapscaleNoteDto: CreateZapscaleNoteDto, xTenantId?: string, options?: any): AxiosPromise<ZapscaleNote> {
            return localVarFp.zsNotesControllerCreateZsNote(createZapscaleNoteDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete note against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerDeleteNote(id: string, xTenantId?: string, options?: any): AxiosPromise<ZapscaleNote> {
            return localVarFp.zsNotesControllerDeleteNote(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all zapscale notes
         * @param {ZsNoteFilterDto} zsNoteFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerFilter(zsNoteFilterDto: ZsNoteFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedZapscaleNoteDto> {
            return localVarFp.zsNotesControllerFilter(zsNoteFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all zapscale notes for a c2
         * @param {ZsNoteFilterDto} zsNoteFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerFilterPaginatedNotesForC2(zsNoteFilterDto: ZsNoteFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedZapscaleNoteDto> {
            return localVarFp.zsNotesControllerFilterPaginatedNotesForC2(zsNoteFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update note against ID
         * @param {string} id 
         * @param {UpdateZapscaleNoteDto} updateZapscaleNoteDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zsNotesControllerUpdateNote(id: string, updateZapscaleNoteDto: UpdateZapscaleNoteDto, xTenantId?: string, options?: any): AxiosPromise<ZapscaleNote> {
            return localVarFp.zsNotesControllerUpdateNote(id, updateZapscaleNoteDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for zsNotesControllerCreateZsNote operation in ZsNotesApi.
 * @export
 * @interface ZsNotesApiZsNotesControllerCreateZsNoteRequest
 */
export interface ZsNotesApiZsNotesControllerCreateZsNoteRequest {
    /**
     * 
     * @type {CreateZapscaleNoteDto}
     * @memberof ZsNotesApiZsNotesControllerCreateZsNote
     */
    readonly createZapscaleNoteDto: CreateZapscaleNoteDto

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerCreateZsNote
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zsNotesControllerDeleteNote operation in ZsNotesApi.
 * @export
 * @interface ZsNotesApiZsNotesControllerDeleteNoteRequest
 */
export interface ZsNotesApiZsNotesControllerDeleteNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerDeleteNote
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerDeleteNote
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for zsNotesControllerFilter operation in ZsNotesApi.
 * @export
 * @interface ZsNotesApiZsNotesControllerFilterRequest
 */
export interface ZsNotesApiZsNotesControllerFilterRequest {
    /**
     * 
     * @type {ZsNoteFilterDto}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly zsNoteFilterDto: ZsNoteFilterDto

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilter
     */
    readonly after?: string
}

/**
 * Request parameters for zsNotesControllerFilterPaginatedNotesForC2 operation in ZsNotesApi.
 * @export
 * @interface ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2Request
 */
export interface ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2Request {
    /**
     * 
     * @type {ZsNoteFilterDto}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly zsNoteFilterDto: ZsNoteFilterDto

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2
     */
    readonly after?: string
}

/**
 * Request parameters for zsNotesControllerUpdateNote operation in ZsNotesApi.
 * @export
 * @interface ZsNotesApiZsNotesControllerUpdateNoteRequest
 */
export interface ZsNotesApiZsNotesControllerUpdateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerUpdateNote
     */
    readonly id: string

    /**
     * 
     * @type {UpdateZapscaleNoteDto}
     * @memberof ZsNotesApiZsNotesControllerUpdateNote
     */
    readonly updateZapscaleNoteDto: UpdateZapscaleNoteDto

    /**
     * 
     * @type {string}
     * @memberof ZsNotesApiZsNotesControllerUpdateNote
     */
    readonly xTenantId?: string
}

/**
 * ZsNotesApi - object-oriented interface
 * @export
 * @class ZsNotesApi
 * @extends {BaseAPI}
 */
export class ZsNotesApi extends BaseAPI {
    /**
     * 
     * @summary Create zapscale note
     * @param {ZsNotesApiZsNotesControllerCreateZsNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZsNotesApi
     */
    public zsNotesControllerCreateZsNote(requestParameters: ZsNotesApiZsNotesControllerCreateZsNoteRequest, options?: AxiosRequestConfig) {
        return ZsNotesApiFp(this.configuration).zsNotesControllerCreateZsNote(requestParameters.createZapscaleNoteDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete note against ID
     * @param {ZsNotesApiZsNotesControllerDeleteNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZsNotesApi
     */
    public zsNotesControllerDeleteNote(requestParameters: ZsNotesApiZsNotesControllerDeleteNoteRequest, options?: AxiosRequestConfig) {
        return ZsNotesApiFp(this.configuration).zsNotesControllerDeleteNote(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all zapscale notes
     * @param {ZsNotesApiZsNotesControllerFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZsNotesApi
     */
    public zsNotesControllerFilter(requestParameters: ZsNotesApiZsNotesControllerFilterRequest, options?: AxiosRequestConfig) {
        return ZsNotesApiFp(this.configuration).zsNotesControllerFilter(requestParameters.zsNoteFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all zapscale notes for a c2
     * @param {ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZsNotesApi
     */
    public zsNotesControllerFilterPaginatedNotesForC2(requestParameters: ZsNotesApiZsNotesControllerFilterPaginatedNotesForC2Request, options?: AxiosRequestConfig) {
        return ZsNotesApiFp(this.configuration).zsNotesControllerFilterPaginatedNotesForC2(requestParameters.zsNoteFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update note against ID
     * @param {ZsNotesApiZsNotesControllerUpdateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZsNotesApi
     */
    public zsNotesControllerUpdateNote(requestParameters: ZsNotesApiZsNotesControllerUpdateNoteRequest, options?: AxiosRequestConfig) {
        return ZsNotesApiFp(this.configuration).zsNotesControllerUpdateNote(requestParameters.id, requestParameters.updateZapscaleNoteDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
