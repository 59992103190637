/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2ProductU1Mapping } from '../model/';
// @ts-ignore
import { C2ProductU1MappingRemoveU1Dto } from '../model/';
// @ts-ignore
import { FilterC2ProductU1MappingsDto } from '../model/';
// @ts-ignore
import { PaginatedC2ProductU1MappingsDto } from '../model/';
// @ts-ignore
import { UpdateC2ProductU1MappingDto } from '../model/';
/**
 * C2ProductU1MappingApi - axios parameter creator
 * @export
 */
export const C2ProductU1MappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if u1 is present in any c2 product u1 mapping
         * @param {string} [xTenantId] 
         * @param {boolean} [checkCs] Check cs_u1_ids if true
         * @param {boolean} [checkAccount] Check account_manager_u1_ids if true
         * @param {boolean} [checkSales] Check sales_u1_ids if true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerCheckU1Assignment: async (xTenantId?: string, checkCs?: boolean, checkAccount?: boolean, checkSales?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-product-u1-mappings/check-u1-assignment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (checkCs !== undefined) {
                localVarQueryParameter['check_cs'] = checkCs;
            }

            if (checkAccount !== undefined) {
                localVarQueryParameter['check_account'] = checkAccount;
            }

            if (checkSales !== undefined) {
                localVarQueryParameter['check_sales'] = checkSales;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch C2 Product Mappings
         * @param {FilterC2ProductU1MappingsDto} filterC2ProductU1MappingsDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerFetchAll: async (filterC2ProductU1MappingsDto: FilterC2ProductU1MappingsDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterC2ProductU1MappingsDto' is not null or undefined
            assertParamExists('c2ProductU1MappingsControllerFetchAll', 'filterC2ProductU1MappingsDto', filterC2ProductU1MappingsDto)
            const localVarPath = `/api/c2-product-u1-mappings/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterC2ProductU1MappingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download assigned csm list
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerFindAllCSMInfoForCSV: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-product-u1-mappings/assigned-csm-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all product mappings for current u1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerFindAllMappingsForCurrentU1: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-product-u1-mappings/current-u1-product-mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove u1 from c2 product u1 mapping
         * @param {C2ProductU1MappingRemoveU1Dto} c2ProductU1MappingRemoveU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerRemoveU1Assignment: async (c2ProductU1MappingRemoveU1Dto: C2ProductU1MappingRemoveU1Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2ProductU1MappingRemoveU1Dto' is not null or undefined
            assertParamExists('c2ProductU1MappingsControllerRemoveU1Assignment', 'c2ProductU1MappingRemoveU1Dto', c2ProductU1MappingRemoveU1Dto)
            const localVarPath = `/api/c2-product-u1-mappings/remove-u1-assignment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2ProductU1MappingRemoveU1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C2 Product Mappings
         * @param {Array<UpdateC2ProductU1MappingDto>} updateC2ProductU1MappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerUpdate: async (updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateC2ProductU1MappingDto' is not null or undefined
            assertParamExists('c2ProductU1MappingsControllerUpdate', 'updateC2ProductU1MappingDto', updateC2ProductU1MappingDto)
            const localVarPath = `/api/c2-product-u1-mappings/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2ProductU1MappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update All C2 Product Mappings
         * @param {Array<UpdateC2ProductU1MappingDto>} updateC2ProductU1MappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerUpdateAll: async (updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateC2ProductU1MappingDto' is not null or undefined
            assertParamExists('c2ProductU1MappingsControllerUpdateAll', 'updateC2ProductU1MappingDto', updateC2ProductU1MappingDto)
            const localVarPath = `/api/c2-product-u1-mappings/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2ProductU1MappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2ProductU1MappingApi - functional programming interface
 * @export
 */
export const C2ProductU1MappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2ProductU1MappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if u1 is present in any c2 product u1 mapping
         * @param {string} [xTenantId] 
         * @param {boolean} [checkCs] Check cs_u1_ids if true
         * @param {boolean} [checkAccount] Check account_manager_u1_ids if true
         * @param {boolean} [checkSales] Check sales_u1_ids if true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerCheckU1Assignment(xTenantId?: string, checkCs?: boolean, checkAccount?: boolean, checkSales?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerCheckU1Assignment(xTenantId, checkCs, checkAccount, checkSales, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch C2 Product Mappings
         * @param {FilterC2ProductU1MappingsDto} filterC2ProductU1MappingsDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerFetchAll(filterC2ProductU1MappingsDto: FilterC2ProductU1MappingsDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2ProductU1MappingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerFetchAll(filterC2ProductU1MappingsDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download assigned csm list
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerFindAllCSMInfoForCSV(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerFindAllCSMInfoForCSV(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all product mappings for current u1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerFindAllMappingsForCurrentU1(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2ProductU1Mapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerFindAllMappingsForCurrentU1(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove u1 from c2 product u1 mapping
         * @param {C2ProductU1MappingRemoveU1Dto} c2ProductU1MappingRemoveU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerRemoveU1Assignment(c2ProductU1MappingRemoveU1Dto: C2ProductU1MappingRemoveU1Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerRemoveU1Assignment(c2ProductU1MappingRemoveU1Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C2 Product Mappings
         * @param {Array<UpdateC2ProductU1MappingDto>} updateC2ProductU1MappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerUpdate(updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2ProductU1MappingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerUpdate(updateC2ProductU1MappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update All C2 Product Mappings
         * @param {Array<UpdateC2ProductU1MappingDto>} updateC2ProductU1MappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ProductU1MappingsControllerUpdateAll(updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2ProductU1MappingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ProductU1MappingsControllerUpdateAll(updateC2ProductU1MappingDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2ProductU1MappingApi - factory interface
 * @export
 */
export const C2ProductU1MappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2ProductU1MappingApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if u1 is present in any c2 product u1 mapping
         * @param {string} [xTenantId] 
         * @param {boolean} [checkCs] Check cs_u1_ids if true
         * @param {boolean} [checkAccount] Check account_manager_u1_ids if true
         * @param {boolean} [checkSales] Check sales_u1_ids if true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerCheckU1Assignment(xTenantId?: string, checkCs?: boolean, checkAccount?: boolean, checkSales?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.c2ProductU1MappingsControllerCheckU1Assignment(xTenantId, checkCs, checkAccount, checkSales, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch C2 Product Mappings
         * @param {FilterC2ProductU1MappingsDto} filterC2ProductU1MappingsDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerFetchAll(filterC2ProductU1MappingsDto: FilterC2ProductU1MappingsDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedC2ProductU1MappingsDto> {
            return localVarFp.c2ProductU1MappingsControllerFetchAll(filterC2ProductU1MappingsDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download assigned csm list
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerFindAllCSMInfoForCSV(xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.c2ProductU1MappingsControllerFindAllCSMInfoForCSV(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all product mappings for current u1
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerFindAllMappingsForCurrentU1(xTenantId?: string, options?: any): AxiosPromise<Array<C2ProductU1Mapping>> {
            return localVarFp.c2ProductU1MappingsControllerFindAllMappingsForCurrentU1(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove u1 from c2 product u1 mapping
         * @param {C2ProductU1MappingRemoveU1Dto} c2ProductU1MappingRemoveU1Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerRemoveU1Assignment(c2ProductU1MappingRemoveU1Dto: C2ProductU1MappingRemoveU1Dto, xTenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.c2ProductU1MappingsControllerRemoveU1Assignment(c2ProductU1MappingRemoveU1Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C2 Product Mappings
         * @param {Array<UpdateC2ProductU1MappingDto>} updateC2ProductU1MappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerUpdate(updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>, xTenantId?: string, options?: any): AxiosPromise<PaginatedC2ProductU1MappingsDto> {
            return localVarFp.c2ProductU1MappingsControllerUpdate(updateC2ProductU1MappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update All C2 Product Mappings
         * @param {Array<UpdateC2ProductU1MappingDto>} updateC2ProductU1MappingDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ProductU1MappingsControllerUpdateAll(updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>, xTenantId?: string, options?: any): AxiosPromise<PaginatedC2ProductU1MappingsDto> {
            return localVarFp.c2ProductU1MappingsControllerUpdateAll(updateC2ProductU1MappingDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2ProductU1MappingsControllerCheckU1Assignment operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1AssignmentRequest
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1AssignmentRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1Assignment
     */
    readonly xTenantId?: string

    /**
     * Check cs_u1_ids if true
     * @type {boolean}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1Assignment
     */
    readonly checkCs?: boolean

    /**
     * Check account_manager_u1_ids if true
     * @type {boolean}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1Assignment
     */
    readonly checkAccount?: boolean

    /**
     * Check sales_u1_ids if true
     * @type {boolean}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1Assignment
     */
    readonly checkSales?: boolean
}

/**
 * Request parameters for c2ProductU1MappingsControllerFetchAll operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAllRequest
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAllRequest {
    /**
     * 
     * @type {FilterC2ProductU1MappingsDto}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly filterC2ProductU1MappingsDto: FilterC2ProductU1MappingsDto

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAll
     */
    readonly after?: string
}

/**
 * Request parameters for c2ProductU1MappingsControllerFindAllCSMInfoForCSV operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllCSMInfoForCSVRequest
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllCSMInfoForCSVRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllCSMInfoForCSV
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ProductU1MappingsControllerFindAllMappingsForCurrentU1 operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllMappingsForCurrentU1Request
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllMappingsForCurrentU1Request {
    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllMappingsForCurrentU1
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ProductU1MappingsControllerRemoveU1Assignment operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerRemoveU1AssignmentRequest
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerRemoveU1AssignmentRequest {
    /**
     * 
     * @type {C2ProductU1MappingRemoveU1Dto}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerRemoveU1Assignment
     */
    readonly c2ProductU1MappingRemoveU1Dto: C2ProductU1MappingRemoveU1Dto

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerRemoveU1Assignment
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ProductU1MappingsControllerUpdate operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateRequest
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateRequest {
    /**
     * 
     * @type {Array<UpdateC2ProductU1MappingDto>}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerUpdate
     */
    readonly updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ProductU1MappingsControllerUpdateAll operation in C2ProductU1MappingApi.
 * @export
 * @interface C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateAllRequest
 */
export interface C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateAllRequest {
    /**
     * 
     * @type {Array<UpdateC2ProductU1MappingDto>}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateAll
     */
    readonly updateC2ProductU1MappingDto: Array<UpdateC2ProductU1MappingDto>

    /**
     * 
     * @type {string}
     * @memberof C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateAll
     */
    readonly xTenantId?: string
}

/**
 * C2ProductU1MappingApi - object-oriented interface
 * @export
 * @class C2ProductU1MappingApi
 * @extends {BaseAPI}
 */
export class C2ProductU1MappingApi extends BaseAPI {
    /**
     * 
     * @summary Check if u1 is present in any c2 product u1 mapping
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1AssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerCheckU1Assignment(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerCheckU1AssignmentRequest = {}, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerCheckU1Assignment(requestParameters.xTenantId, requestParameters.checkCs, requestParameters.checkAccount, requestParameters.checkSales, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch C2 Product Mappings
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerFetchAll(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerFetchAllRequest, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerFetchAll(requestParameters.filterC2ProductU1MappingsDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download assigned csm list
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllCSMInfoForCSVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerFindAllCSMInfoForCSV(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllCSMInfoForCSVRequest = {}, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerFindAllCSMInfoForCSV(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all product mappings for current u1
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllMappingsForCurrentU1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerFindAllMappingsForCurrentU1(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerFindAllMappingsForCurrentU1Request = {}, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerFindAllMappingsForCurrentU1(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove u1 from c2 product u1 mapping
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerRemoveU1AssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerRemoveU1Assignment(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerRemoveU1AssignmentRequest, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerRemoveU1Assignment(requestParameters.c2ProductU1MappingRemoveU1Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C2 Product Mappings
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerUpdate(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerUpdate(requestParameters.updateC2ProductU1MappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update All C2 Product Mappings
     * @param {C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2ProductU1MappingApi
     */
    public c2ProductU1MappingsControllerUpdateAll(requestParameters: C2ProductU1MappingApiC2ProductU1MappingsControllerUpdateAllRequest, options?: AxiosRequestConfig) {
        return C2ProductU1MappingApiFp(this.configuration).c2ProductU1MappingsControllerUpdateAll(requestParameters.updateC2ProductU1MappingDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
