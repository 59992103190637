/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2 } from '../model/';
// @ts-ignore
import { C2AppIdListDto } from '../model/';
// @ts-ignore
import { C2LocationDto } from '../model/';
// @ts-ignore
import { C2MappingFilterDto } from '../model/';
// @ts-ignore
import { C2MappingMatchCountDto } from '../model/';
// @ts-ignore
import { CompositeUpdateC2TypeDto } from '../model/';
// @ts-ignore
import { CreateC2Dto } from '../model/';
// @ts-ignore
import { CustomerMappingCountV2Dto } from '../model/';
// @ts-ignore
import { CustomerMappingV2FilterDto } from '../model/';
// @ts-ignore
import { DeleteC2Dto } from '../model/';
// @ts-ignore
import { PaginatedC2Dto } from '../model/';
// @ts-ignore
import { UpdateC2Dto } from '../model/';
/**
 * C2Api - axios parameter creator
 * @export
 */
export const C2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary C2 Mapping Filtered List
         * @param {C2MappingFilterDto} c2MappingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingFilter: async (c2MappingFilterDto: C2MappingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2MappingFilterDto' is not null or undefined
            assertParamExists('c2ControllerC2MappingFilter', 'c2MappingFilterDto', c2MappingFilterDto)
            const localVarPath = `/api/c2/c2-mapping-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2MappingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary C2 Mapping Filtered List - V2
         * @param {CustomerMappingV2FilterDto} customerMappingV2FilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingFilterV2: async (customerMappingV2FilterDto: CustomerMappingV2FilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerMappingV2FilterDto' is not null or undefined
            assertParamExists('c2ControllerC2MappingFilterV2', 'customerMappingV2FilterDto', customerMappingV2FilterDto)
            const localVarPath = `/api/v2/c2/c2-mapping-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerMappingV2FilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary C2 Mapping Match Count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingMatchCount: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2/c2-mapping-match-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary C2 Mapping Match Count - V2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingMatchCountV2: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/c2/c2-mapping-match-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create C2
         * @param {CreateC2Dto} createC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerCreate: async (createC2Dto: CreateC2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2Dto' is not null or undefined
            assertParamExists('c2ControllerCreate', 'createC2Dto', createC2Dto)
            const localVarPath = `/api/c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer using id
         * @param {DeleteC2Dto} deleteC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerDeleteCustomer: async (deleteC2Dto: DeleteC2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteC2Dto' is not null or undefined
            assertParamExists('c2ControllerDeleteCustomer', 'deleteC2Dto', deleteC2Dto)
            const localVarPath = `/api/c2/delete-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteC2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2 within current segment
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {Array<string>} [c2Ids] Optional field
         * @param {string} [location] Optional field
         * @param {string} [c2AppId] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAll: async (xTenantId?: string, name?: string, c2AppIds?: Array<string>, c2Ids?: Array<string>, location?: string, c2AppId?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (c2AppIds) {
                localVarQueryParameter['c2_app_ids'] = c2AppIds;
            }

            if (c2Ids) {
                localVarQueryParameter['c2_ids'] = c2Ids;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (c2AppId !== undefined) {
                localVarQueryParameter['c2_app_id'] = c2AppId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2 List by Location
         * @param {string} location 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllC2ByLocation: async (location: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'location' is not null or undefined
            assertParamExists('c2ControllerFindAllC2ByLocation', 'location', location)
            const localVarPath = `/api/c2/c2-location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find List of C2s from C2 App Id for Throttle
         * @param {C2AppIdListDto} c2AppIdListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllC2sForThrottle: async (c2AppIdListDto: C2AppIdListDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2AppIdListDto' is not null or undefined
            assertParamExists('c2ControllerFindAllC2sForThrottle', 'c2AppIdListDto', c2AppIdListDto)
            const localVarPath = `/api/c2/c2-list-for-throttle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2AppIdListDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saas app customer list
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllCustomerForCSV: async (c2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('c2ControllerFindAllCustomerForCSV', 'c2TypeId', c2TypeId)
            const localVarPath = `/api/c2/saas-app-customer-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2s
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {Array<string>} [c2Ids] Optional field
         * @param {string} [location] Optional field
         * @param {string} [c2AppId] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllV1: async (xTenantId?: string, name?: string, c2AppIds?: Array<string>, c2Ids?: Array<string>, location?: string, c2AppId?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (c2AppIds) {
                localVarQueryParameter['c2_app_ids'] = c2AppIds;
            }

            if (c2Ids) {
                localVarQueryParameter['c2_ids'] = c2Ids;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (c2AppId !== undefined) {
                localVarQueryParameter['c2_app_id'] = c2AppId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated List of C2
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllWithPagination: async (xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2/paginated-c2-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2s Available For Type Change
         * @param {CompositeUpdateC2TypeDto} compositeUpdateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindC2sAvailableForTypeChange: async (compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compositeUpdateC2TypeDto' is not null or undefined
            assertParamExists('c2ControllerFindC2sAvailableForTypeChange', 'compositeUpdateC2TypeDto', compositeUpdateC2TypeDto)
            const localVarPath = `/api/c2/c2-available-for-type-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compositeUpdateC2TypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CsGutFeel customer list
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindCustomerDataForGutFeelCSV: async (c2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('c2ControllerFindCustomerDataForGutFeelCSV', 'c2TypeId', c2TypeId)
            const localVarPath = `/api/c2/csgutfeel-customer-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saas app customer list under custom field template
         * @param {string} c2TypeId 
         * @param {string} isAllCheckboxSelected 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindCustomerWithProdIdForCSV: async (c2TypeId: string, isAllCheckboxSelected: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('c2ControllerFindCustomerWithProdIdForCSV', 'c2TypeId', c2TypeId)
            // verify required parameter 'isAllCheckboxSelected' is not null or undefined
            assertParamExists('c2ControllerFindCustomerWithProdIdForCSV', 'isAllCheckboxSelected', isAllCheckboxSelected)
            const localVarPath = `/api/c2/saas-app-custom-field-template-customer-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (isAllCheckboxSelected !== undefined) {
                localVarQueryParameter['isAllCheckboxSelected'] = isAllCheckboxSelected;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find C2 against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2ControllerFindOne', 'id', id)
            const localVarPath = `/api/c2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saas app customer csv with selected custom fields
         * @param {string} c2TypeId 
         * @param {Array<string>} selectedCustomFieldNames 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindSelectedCustomFieldsWithCustomerForCSV: async (c2TypeId: string, selectedCustomFieldNames: Array<string>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('c2ControllerFindSelectedCustomFieldsWithCustomerForCSV', 'c2TypeId', c2TypeId)
            // verify required parameter 'selectedCustomFieldNames' is not null or undefined
            assertParamExists('c2ControllerFindSelectedCustomFieldsWithCustomerForCSV', 'selectedCustomFieldNames', selectedCustomFieldNames)
            const localVarPath = `/api/c2/saas-app-customer-selected-custom-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (selectedCustomFieldNames) {
                localVarQueryParameter['selectedCustomFieldNames'] = selectedCustomFieldNames;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find total count of customers
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindTotalCount: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete C2 against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerRemove: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2ControllerRemove', 'id', id)
            const localVarPath = `/api/c2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C2 against ID
         * @param {string} id 
         * @param {UpdateC2Dto} updateC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerUpdate: async (id: string, updateC2Dto: UpdateC2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2ControllerUpdate', 'id', id)
            // verify required parameter 'updateC2Dto' is not null or undefined
            assertParamExists('c2ControllerUpdate', 'updateC2Dto', updateC2Dto)
            const localVarPath = `/api/c2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update C2 Type against ID
         * @param {string} id 
         * @param {UpdateC2Dto} updateC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerUpdateC2Type: async (id: string, updateC2Dto: UpdateC2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2ControllerUpdateC2Type', 'id', id)
            // verify required parameter 'updateC2Dto' is not null or undefined
            assertParamExists('c2ControllerUpdateC2Type', 'updateC2Dto', updateC2Dto)
            const localVarPath = `/api/c2/{id}/update-c2-type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2Api - functional programming interface
 * @export
 */
export const C2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary C2 Mapping Filtered List
         * @param {C2MappingFilterDto} c2MappingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerC2MappingFilter(c2MappingFilterDto: C2MappingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerC2MappingFilter(c2MappingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary C2 Mapping Filtered List - V2
         * @param {CustomerMappingV2FilterDto} customerMappingV2FilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerC2MappingFilterV2(customerMappingV2FilterDto: CustomerMappingV2FilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerC2MappingFilterV2(customerMappingV2FilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary C2 Mapping Match Count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerC2MappingMatchCount(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2MappingMatchCountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerC2MappingMatchCount(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary C2 Mapping Match Count - V2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerC2MappingMatchCountV2(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMappingCountV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerC2MappingMatchCountV2(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create C2
         * @param {CreateC2Dto} createC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerCreate(createC2Dto: CreateC2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerCreate(createC2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete customer using id
         * @param {DeleteC2Dto} deleteC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerDeleteCustomer(deleteC2Dto: DeleteC2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerDeleteCustomer(deleteC2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2 within current segment
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {Array<string>} [c2Ids] Optional field
         * @param {string} [location] Optional field
         * @param {string} [c2AppId] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindAll(xTenantId?: string, name?: string, c2AppIds?: Array<string>, c2Ids?: Array<string>, location?: string, c2AppId?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindAll(xTenantId, name, c2AppIds, c2Ids, location, c2AppId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2 List by Location
         * @param {string} location 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindAllC2ByLocation(location: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2LocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindAllC2ByLocation(location, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find List of C2s from C2 App Id for Throttle
         * @param {C2AppIdListDto} c2AppIdListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindAllC2sForThrottle(c2AppIdListDto: C2AppIdListDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindAllC2sForThrottle(c2AppIdListDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saas app customer list
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindAllCustomerForCSV(c2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindAllCustomerForCSV(c2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2s
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {Array<string>} [c2Ids] Optional field
         * @param {string} [location] Optional field
         * @param {string} [c2AppId] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindAllV1(xTenantId?: string, name?: string, c2AppIds?: Array<string>, c2Ids?: Array<string>, location?: string, c2AppId?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindAllV1(xTenantId, name, c2AppIds, c2Ids, location, c2AppId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated List of C2
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedC2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2s Available For Type Change
         * @param {CompositeUpdateC2TypeDto} compositeUpdateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindC2sAvailableForTypeChange(compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindC2sAvailableForTypeChange(compositeUpdateC2TypeDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CsGutFeel customer list
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindCustomerDataForGutFeelCSV(c2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindCustomerDataForGutFeelCSV(c2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saas app customer list under custom field template
         * @param {string} c2TypeId 
         * @param {string} isAllCheckboxSelected 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindCustomerWithProdIdForCSV(c2TypeId: string, isAllCheckboxSelected: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindCustomerWithProdIdForCSV(c2TypeId, isAllCheckboxSelected, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find C2 against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saas app customer csv with selected custom fields
         * @param {string} c2TypeId 
         * @param {Array<string>} selectedCustomFieldNames 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindSelectedCustomFieldsWithCustomerForCSV(c2TypeId: string, selectedCustomFieldNames: Array<string>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindSelectedCustomFieldsWithCustomerForCSV(c2TypeId, selectedCustomFieldNames, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find total count of customers
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerFindTotalCount(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerFindTotalCount(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete C2 against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerRemove(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerRemove(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C2 against ID
         * @param {string} id 
         * @param {UpdateC2Dto} updateC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerUpdate(id: string, updateC2Dto: UpdateC2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerUpdate(id, updateC2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update C2 Type against ID
         * @param {string} id 
         * @param {UpdateC2Dto} updateC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2ControllerUpdateC2Type(id: string, updateC2Dto: UpdateC2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2ControllerUpdateC2Type(id, updateC2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2Api - factory interface
 * @export
 */
export const C2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2ApiFp(configuration)
    return {
        /**
         * 
         * @summary C2 Mapping Filtered List
         * @param {C2MappingFilterDto} c2MappingFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingFilter(c2MappingFilterDto: C2MappingFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedC2Dto> {
            return localVarFp.c2ControllerC2MappingFilter(c2MappingFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary C2 Mapping Filtered List - V2
         * @param {CustomerMappingV2FilterDto} customerMappingV2FilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingFilterV2(customerMappingV2FilterDto: CustomerMappingV2FilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedC2Dto> {
            return localVarFp.c2ControllerC2MappingFilterV2(customerMappingV2FilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary C2 Mapping Match Count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingMatchCount(xTenantId?: string, options?: any): AxiosPromise<C2MappingMatchCountDto> {
            return localVarFp.c2ControllerC2MappingMatchCount(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary C2 Mapping Match Count - V2
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerC2MappingMatchCountV2(xTenantId?: string, options?: any): AxiosPromise<CustomerMappingCountV2Dto> {
            return localVarFp.c2ControllerC2MappingMatchCountV2(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create C2
         * @param {CreateC2Dto} createC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerCreate(createC2Dto: CreateC2Dto, xTenantId?: string, options?: any): AxiosPromise<C2> {
            return localVarFp.c2ControllerCreate(createC2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer using id
         * @param {DeleteC2Dto} deleteC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerDeleteCustomer(deleteC2Dto: DeleteC2Dto, xTenantId?: string, options?: any): AxiosPromise<C2> {
            return localVarFp.c2ControllerDeleteCustomer(deleteC2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2 within current segment
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {Array<string>} [c2Ids] Optional field
         * @param {string} [location] Optional field
         * @param {string} [c2AppId] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAll(xTenantId?: string, name?: string, c2AppIds?: Array<string>, c2Ids?: Array<string>, location?: string, c2AppId?: string, date?: string, options?: any): AxiosPromise<Array<C2>> {
            return localVarFp.c2ControllerFindAll(xTenantId, name, c2AppIds, c2Ids, location, c2AppId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2 List by Location
         * @param {string} location 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllC2ByLocation(location: string, xTenantId?: string, options?: any): AxiosPromise<Array<C2LocationDto>> {
            return localVarFp.c2ControllerFindAllC2ByLocation(location, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find List of C2s from C2 App Id for Throttle
         * @param {C2AppIdListDto} c2AppIdListDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllC2sForThrottle(c2AppIdListDto: C2AppIdListDto, xTenantId?: string, options?: any): AxiosPromise<Array<C2>> {
            return localVarFp.c2ControllerFindAllC2sForThrottle(c2AppIdListDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saas app customer list
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllCustomerForCSV(c2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.c2ControllerFindAllCustomerForCSV(c2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2s
         * @param {string} [xTenantId] 
         * @param {string} [name] Optional field
         * @param {Array<string>} [c2AppIds] Optional field
         * @param {Array<string>} [c2Ids] Optional field
         * @param {string} [location] Optional field
         * @param {string} [c2AppId] Optional field
         * @param {string} [date] Optional date utc field, if provided list will be displayed for that date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllV1(xTenantId?: string, name?: string, c2AppIds?: Array<string>, c2Ids?: Array<string>, location?: string, c2AppId?: string, date?: string, options?: any): AxiosPromise<Array<C2>> {
            return localVarFp.c2ControllerFindAllV1(xTenantId, name, c2AppIds, c2Ids, location, c2AppId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated List of C2
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindAllWithPagination(xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedC2Dto> {
            return localVarFp.c2ControllerFindAllWithPagination(xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2s Available For Type Change
         * @param {CompositeUpdateC2TypeDto} compositeUpdateC2TypeDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindC2sAvailableForTypeChange(compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto, xTenantId?: string, options?: any): AxiosPromise<Array<C2>> {
            return localVarFp.c2ControllerFindC2sAvailableForTypeChange(compositeUpdateC2TypeDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CsGutFeel customer list
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindCustomerDataForGutFeelCSV(c2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.c2ControllerFindCustomerDataForGutFeelCSV(c2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saas app customer list under custom field template
         * @param {string} c2TypeId 
         * @param {string} isAllCheckboxSelected 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindCustomerWithProdIdForCSV(c2TypeId: string, isAllCheckboxSelected: string, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.c2ControllerFindCustomerWithProdIdForCSV(c2TypeId, isAllCheckboxSelected, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find C2 against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C2> {
            return localVarFp.c2ControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saas app customer csv with selected custom fields
         * @param {string} c2TypeId 
         * @param {Array<string>} selectedCustomFieldNames 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindSelectedCustomFieldsWithCustomerForCSV(c2TypeId: string, selectedCustomFieldNames: Array<string>, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.c2ControllerFindSelectedCustomFieldsWithCustomerForCSV(c2TypeId, selectedCustomFieldNames, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find total count of customers
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerFindTotalCount(xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.c2ControllerFindTotalCount(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete C2 against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerRemove(id: string, xTenantId?: string, options?: any): AxiosPromise<C2> {
            return localVarFp.c2ControllerRemove(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C2 against ID
         * @param {string} id 
         * @param {UpdateC2Dto} updateC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerUpdate(id: string, updateC2Dto: UpdateC2Dto, xTenantId?: string, options?: any): AxiosPromise<C2> {
            return localVarFp.c2ControllerUpdate(id, updateC2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update C2 Type against ID
         * @param {string} id 
         * @param {UpdateC2Dto} updateC2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2ControllerUpdateC2Type(id: string, updateC2Dto: UpdateC2Dto, xTenantId?: string, options?: any): AxiosPromise<C2> {
            return localVarFp.c2ControllerUpdateC2Type(id, updateC2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2ControllerC2MappingFilter operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerC2MappingFilterRequest
 */
export interface C2ApiC2ControllerC2MappingFilterRequest {
    /**
     * 
     * @type {C2MappingFilterDto}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly c2MappingFilterDto: C2MappingFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilter
     */
    readonly after?: string
}

/**
 * Request parameters for c2ControllerC2MappingFilterV2 operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerC2MappingFilterV2Request
 */
export interface C2ApiC2ControllerC2MappingFilterV2Request {
    /**
     * 
     * @type {CustomerMappingV2FilterDto}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly customerMappingV2FilterDto: CustomerMappingV2FilterDto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for c2ControllerC2MappingMatchCount operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerC2MappingMatchCountRequest
 */
export interface C2ApiC2ControllerC2MappingMatchCountRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingMatchCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerC2MappingMatchCountV2 operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerC2MappingMatchCountV2Request
 */
export interface C2ApiC2ControllerC2MappingMatchCountV2Request {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerC2MappingMatchCountV2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerCreate operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerCreateRequest
 */
export interface C2ApiC2ControllerCreateRequest {
    /**
     * 
     * @type {CreateC2Dto}
     * @memberof C2ApiC2ControllerCreate
     */
    readonly createC2Dto: CreateC2Dto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerDeleteCustomer operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerDeleteCustomerRequest
 */
export interface C2ApiC2ControllerDeleteCustomerRequest {
    /**
     * 
     * @type {DeleteC2Dto}
     * @memberof C2ApiC2ControllerDeleteCustomer
     */
    readonly deleteC2Dto: DeleteC2Dto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerDeleteCustomer
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindAll operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindAllRequest
 */
export interface C2ApiC2ControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly name?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly c2AppIds?: Array<string>

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly c2Ids?: Array<string>

    /**
     * Optional field
     * @type {string}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly location?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly c2AppId?: string

    /**
     * Optional date utc field, if provided list will be displayed for that date
     * @type {string}
     * @memberof C2ApiC2ControllerFindAll
     */
    readonly date?: string
}

/**
 * Request parameters for c2ControllerFindAllC2ByLocation operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindAllC2ByLocationRequest
 */
export interface C2ApiC2ControllerFindAllC2ByLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllC2ByLocation
     */
    readonly location: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllC2ByLocation
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindAllC2sForThrottle operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindAllC2sForThrottleRequest
 */
export interface C2ApiC2ControllerFindAllC2sForThrottleRequest {
    /**
     * 
     * @type {C2AppIdListDto}
     * @memberof C2ApiC2ControllerFindAllC2sForThrottle
     */
    readonly c2AppIdListDto: C2AppIdListDto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllC2sForThrottle
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindAllCustomerForCSV operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindAllCustomerForCSVRequest
 */
export interface C2ApiC2ControllerFindAllCustomerForCSVRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllCustomerForCSV
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllCustomerForCSV
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindAllV1 operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindAllV1Request
 */
export interface C2ApiC2ControllerFindAllV1Request {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly name?: string

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly c2AppIds?: Array<string>

    /**
     * Optional field
     * @type {Array<string>}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly c2Ids?: Array<string>

    /**
     * Optional field
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly location?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly c2AppId?: string

    /**
     * Optional date utc field, if provided list will be displayed for that date
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllV1
     */
    readonly date?: string
}

/**
 * Request parameters for c2ControllerFindAllWithPagination operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindAllWithPaginationRequest
 */
export interface C2ApiC2ControllerFindAllWithPaginationRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindAllWithPagination
     */
    readonly after?: string
}

/**
 * Request parameters for c2ControllerFindC2sAvailableForTypeChange operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindC2sAvailableForTypeChangeRequest
 */
export interface C2ApiC2ControllerFindC2sAvailableForTypeChangeRequest {
    /**
     * 
     * @type {CompositeUpdateC2TypeDto}
     * @memberof C2ApiC2ControllerFindC2sAvailableForTypeChange
     */
    readonly compositeUpdateC2TypeDto: CompositeUpdateC2TypeDto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindC2sAvailableForTypeChange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindCustomerDataForGutFeelCSV operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindCustomerDataForGutFeelCSVRequest
 */
export interface C2ApiC2ControllerFindCustomerDataForGutFeelCSVRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindCustomerDataForGutFeelCSV
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindCustomerDataForGutFeelCSV
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindCustomerWithProdIdForCSV operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindCustomerWithProdIdForCSVRequest
 */
export interface C2ApiC2ControllerFindCustomerWithProdIdForCSVRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindCustomerWithProdIdForCSV
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindCustomerWithProdIdForCSV
     */
    readonly isAllCheckboxSelected: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindCustomerWithProdIdForCSV
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindOne operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindOneRequest
 */
export interface C2ApiC2ControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindSelectedCustomFieldsWithCustomerForCSV operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSVRequest
 */
export interface C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSVRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSV
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSV
     */
    readonly selectedCustomFieldNames: Array<string>

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSV
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerFindTotalCount operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerFindTotalCountRequest
 */
export interface C2ApiC2ControllerFindTotalCountRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerFindTotalCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerRemove operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerRemoveRequest
 */
export interface C2ApiC2ControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerRemove
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerRemove
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerUpdate operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerUpdateRequest
 */
export interface C2ApiC2ControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2Dto}
     * @memberof C2ApiC2ControllerUpdate
     */
    readonly updateC2Dto: UpdateC2Dto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2ControllerUpdateC2Type operation in C2Api.
 * @export
 * @interface C2ApiC2ControllerUpdateC2TypeRequest
 */
export interface C2ApiC2ControllerUpdateC2TypeRequest {
    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerUpdateC2Type
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2Dto}
     * @memberof C2ApiC2ControllerUpdateC2Type
     */
    readonly updateC2Dto: UpdateC2Dto

    /**
     * 
     * @type {string}
     * @memberof C2ApiC2ControllerUpdateC2Type
     */
    readonly xTenantId?: string
}

/**
 * C2Api - object-oriented interface
 * @export
 * @class C2Api
 * @extends {BaseAPI}
 */
export class C2Api extends BaseAPI {
    /**
     * 
     * @summary C2 Mapping Filtered List
     * @param {C2ApiC2ControllerC2MappingFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerC2MappingFilter(requestParameters: C2ApiC2ControllerC2MappingFilterRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerC2MappingFilter(requestParameters.c2MappingFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary C2 Mapping Filtered List - V2
     * @param {C2ApiC2ControllerC2MappingFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerC2MappingFilterV2(requestParameters: C2ApiC2ControllerC2MappingFilterV2Request, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerC2MappingFilterV2(requestParameters.customerMappingV2FilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary C2 Mapping Match Count
     * @param {C2ApiC2ControllerC2MappingMatchCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerC2MappingMatchCount(requestParameters: C2ApiC2ControllerC2MappingMatchCountRequest = {}, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerC2MappingMatchCount(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary C2 Mapping Match Count - V2
     * @param {C2ApiC2ControllerC2MappingMatchCountV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerC2MappingMatchCountV2(requestParameters: C2ApiC2ControllerC2MappingMatchCountV2Request = {}, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerC2MappingMatchCountV2(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create C2
     * @param {C2ApiC2ControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerCreate(requestParameters: C2ApiC2ControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerCreate(requestParameters.createC2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer using id
     * @param {C2ApiC2ControllerDeleteCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerDeleteCustomer(requestParameters: C2ApiC2ControllerDeleteCustomerRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerDeleteCustomer(requestParameters.deleteC2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2 within current segment
     * @param {C2ApiC2ControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindAll(requestParameters: C2ApiC2ControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindAll(requestParameters.xTenantId, requestParameters.name, requestParameters.c2AppIds, requestParameters.c2Ids, requestParameters.location, requestParameters.c2AppId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2 List by Location
     * @param {C2ApiC2ControllerFindAllC2ByLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindAllC2ByLocation(requestParameters: C2ApiC2ControllerFindAllC2ByLocationRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindAllC2ByLocation(requestParameters.location, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find List of C2s from C2 App Id for Throttle
     * @param {C2ApiC2ControllerFindAllC2sForThrottleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindAllC2sForThrottle(requestParameters: C2ApiC2ControllerFindAllC2sForThrottleRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindAllC2sForThrottle(requestParameters.c2AppIdListDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saas app customer list
     * @param {C2ApiC2ControllerFindAllCustomerForCSVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindAllCustomerForCSV(requestParameters: C2ApiC2ControllerFindAllCustomerForCSVRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindAllCustomerForCSV(requestParameters.c2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2s
     * @param {C2ApiC2ControllerFindAllV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindAllV1(requestParameters: C2ApiC2ControllerFindAllV1Request = {}, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindAllV1(requestParameters.xTenantId, requestParameters.name, requestParameters.c2AppIds, requestParameters.c2Ids, requestParameters.location, requestParameters.c2AppId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated List of C2
     * @param {C2ApiC2ControllerFindAllWithPaginationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindAllWithPagination(requestParameters: C2ApiC2ControllerFindAllWithPaginationRequest = {}, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindAllWithPagination(requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2s Available For Type Change
     * @param {C2ApiC2ControllerFindC2sAvailableForTypeChangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindC2sAvailableForTypeChange(requestParameters: C2ApiC2ControllerFindC2sAvailableForTypeChangeRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindC2sAvailableForTypeChange(requestParameters.compositeUpdateC2TypeDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CsGutFeel customer list
     * @param {C2ApiC2ControllerFindCustomerDataForGutFeelCSVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindCustomerDataForGutFeelCSV(requestParameters: C2ApiC2ControllerFindCustomerDataForGutFeelCSVRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindCustomerDataForGutFeelCSV(requestParameters.c2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saas app customer list under custom field template
     * @param {C2ApiC2ControllerFindCustomerWithProdIdForCSVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindCustomerWithProdIdForCSV(requestParameters: C2ApiC2ControllerFindCustomerWithProdIdForCSVRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindCustomerWithProdIdForCSV(requestParameters.c2TypeId, requestParameters.isAllCheckboxSelected, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find C2 against ID
     * @param {C2ApiC2ControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindOne(requestParameters: C2ApiC2ControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saas app customer csv with selected custom fields
     * @param {C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSVRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindSelectedCustomFieldsWithCustomerForCSV(requestParameters: C2ApiC2ControllerFindSelectedCustomFieldsWithCustomerForCSVRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindSelectedCustomFieldsWithCustomerForCSV(requestParameters.c2TypeId, requestParameters.selectedCustomFieldNames, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find total count of customers
     * @param {C2ApiC2ControllerFindTotalCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerFindTotalCount(requestParameters: C2ApiC2ControllerFindTotalCountRequest = {}, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerFindTotalCount(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete C2 against ID
     * @param {C2ApiC2ControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerRemove(requestParameters: C2ApiC2ControllerRemoveRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerRemove(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C2 against ID
     * @param {C2ApiC2ControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerUpdate(requestParameters: C2ApiC2ControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerUpdate(requestParameters.id, requestParameters.updateC2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update C2 Type against ID
     * @param {C2ApiC2ControllerUpdateC2TypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2Api
     */
    public c2ControllerUpdateC2Type(requestParameters: C2ApiC2ControllerUpdateC2TypeRequest, options?: AxiosRequestConfig) {
        return C2ApiFp(this.configuration).c2ControllerUpdateC2Type(requestParameters.id, requestParameters.updateC2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
