import { RouteRecordRaw } from 'vue-router';
import { RouteName } from '@/router/route-name';
import { Layout } from '@/types/layout';

export const ZapbookRoutes: RouteRecordRaw = {
  path: '/zapbook',
  name: RouteName.Zapbook,
  component: () => import('@/views/ZapbookContainer.vue'),
  redirect: { name: RouteName.ZapbookTask },
  meta: {
    title: 'Zapbook',
    group: RouteName.Zapbook,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'tasks',
      name: RouteName.ZapbookTask,
      component: () => import('@/views/Zapbook/ZapbookTask.vue'),
      meta: {
        title: 'Tasks',
      },
    },
    {
      path: 'messages',
      name: RouteName.ZapbookMessage,
      component: () => import('@/views/Zapbook/ZapbookMessages.vue'),
      meta: {
        title: 'Messages',
      },
    },
    {
      path: 'actions',
      name: RouteName.ZapbookActions,
      component: () => import('@/views/Zapbook/ZapbookActions.vue'),
      meta: {
        title: 'Actions',
      },
    },
    {
      path: 'insights',
      name: RouteName.ZapbookInsights,
      component: () => import('@/views/Zapbook/ZapbookInsights.vue'),
      meta: {
        title: 'Insights',
      },
    },
    {
      path: 'performance',
      name: RouteName.ZapbookPerformance,
      component: () => import('@/views/Zapbook/ZapbookPerformance.vue'),
      meta: {
        title: 'Performance',
      },
    },
    {
      path: 'success_plans',
      name: RouteName.ZapbookSuccessPlan,
      component: () => import('@/views/Zapbook/ZapbookSuccessPlan.vue'),
      meta: {
        title: 'Success plan',
      },
    },
  ],
};

export const ZapbookV2Routes: RouteRecordRaw = {
  path: '/zapbook',
  name: RouteName.Zapbook,
  component: () => import('@/views/ZapbookContainer.vue'),
  redirect: { name: RouteName.ZapbookV2Overview },
  meta: {
    title: 'Zapbook',
    group: RouteName.Zapbook,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'overview',
      name: RouteName.ZapbookV2Overview,
      component: () => import('@/views/ZapbookV2/OverviewTab.vue'),
      meta: {
        title: 'Overview | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
    },
    {
      path: 'tasks',
      name: RouteName.ZapbookV2Task,
      component: () => import('@/views/ZapbookV2/ZapbookV2Tasks.vue'),
      meta: {
        title: 'Tasks | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
      children: [
        {
          path: ':id/edit',
          name: RouteName.ZapbookTaskDetail,
          component: () => import('@/views/ZapbookV2/ZapbookTaskDetail.vue'),
          props: true,
          meta: {
            title: 'Task - Details',
          },
        },
      ],
    },
    {
      path: 'notes',
      name: RouteName.ZapbookV2Notes,
      component: () => import('@/views/ZapbookV2/ZapbookV2Notes.vue'),
      meta: {
        title: 'Notes | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
      // children: [
      //   {
      //     path: ':id/edit',
      //     name: RouteName.ZapbookNoteDetail,
      //     component: () => import('@/views/ZapbookV2/ZapbookTaskDetail.vue'),
      //     props: true,
      //     meta: {
      //       title: 'Note - Details',
      //     },
      //   },
      // ],
    },
    {
      path: 'messages',
      name: RouteName.ZapbookV2Message,
      component: () => import('@/views/ZapbookV2/ZapbookV2Messages.vue'),
      meta: {
        title: 'Messages | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
      children: [
        {
          path: ':id/edit',
          name: RouteName.ZapbookMessageDetail,
          component: () => import('@/views/ZapbookV2/ZapbookMessageDetail.vue'),
          props: true,
          meta: {
            title: 'Message - Details',
          },
        },
      ],
    },
    {
      path: 'actions',
      name: RouteName.ZapbookV2Actions,
      component: () => import('@/views/ZapbookV2/ZapbookV2Actions.vue'),
      meta: {
        title: 'Actions | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
      children: [
        {
          path: ':id/edit',
          name: RouteName.ZapbookActionDetail,
          component: () => import('@/views/ZapbookV2/ZapbookActionDetail.vue'),
          props: true,
          meta: {
            title: 'Action - Details',
          },
        },
      ],
    },
    {
      path: 'success-plans',
      name: RouteName.ZapbookV2SuccessPlan,
      component: () => import('@/views/ZapbookV2/ZapbookV2SuccessPlan.vue'),
      meta: {
        title: 'Success Plan | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
    },
    {
      path: 'performance',
      name: RouteName.ZapbookV2Performance,
      component: () => import('@/views/ZapbookV2/ZapbookV2Performance.vue'),
      meta: {
        title: 'Performance | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
    },
    {
      path: 'upsell-deals',
      name: RouteName.ZapbookUpsellDeals,
      component: () => import('@/views/ZapbookV2/ZapbookUpsellDeals.vue'),
      meta: {
        title: 'Upsell Deals | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
    },
    {
      path: 'renewal-deals',
      name: RouteName.ZapbookRenewalDeals,
      component: () => import('@/views/ZapbookV2/ZapbookRenewalDeals.vue'),
      meta: {
        title: 'Renewal Deals | Zapbook',
        group: RouteName.Zapbook,
        layout: Layout.Authenticated,
      },
    },
  ],
};
