import { Layout } from '@/types/layout';
import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';

export const CustomerRoutes: RouteRecordRaw = {
  path: '/customers',
  name: RouteName.Customers,
  component: () => import('../views/CustomersContainer.vue'),
  meta: {
    title: 'Customers',
    group: RouteName.Customers,
    layout: Layout.Authenticated,
  },
};

// Todo: Use RouteName enum instead of hardcoded string for name attributes
export const CustomerDetailsRoutes: RouteRecordRaw = {
  path: '/customers/:id',
  name: RouteName.Customer,
  component: () => import('../views/CustomerDetails.vue'),
  redirect: { name: RouteName.CustomerOverview },
  meta: {
    group: RouteName.Customers,
    layout: Layout.Authenticated,
  },
  children: [
    {
      path: 'overview',
      name: RouteName.CustomerOverview,
      component: () => import('../views/CustomerDetails/CustomerOverview.vue'),
      meta: {
        title: 'Overview',
      },
    },
    {
      path: 'kpis',
      name: RouteName.CustomerKpis,
      component: () => import('../views/CustomerDetails/CustomerKpis.vue'),
      redirect: { name: RouteName.CustomerKpiUsage },
      children: [
        {
          path: 'usage',
          name: RouteName.CustomerKpiUsage,
          component: () => import('../views/CustomerDetails/CustomerUsageSuccess.vue'),
          meta: {
            title: 'Usage Success',
          },
        },
        {
          path: 'outcome',
          name: RouteName.CustomerKpiOutcome,
          component: () => import('../views/CustomerDetails/CustomerOutcomeSuccess.vue'),
          meta: {
            title: 'Outcome Success',
          },
        },

        {
          path: 'peripheral',
          name: RouteName.CustomerKpiPeripheral,
          component: () => import('../views/CustomerDetails/CustomerPeripheralSuccess.vue'),
          meta: {
            title: 'Peripheral Success',
          },
        },

        {
          path: 'upsell',
          name: RouteName.CustomerKpiUpsell,
          component: () => import('../views/CustomerDetails/CustomerUpsellSuccess.vue'),
          meta: {
            title: 'Upsell Success',
          },
        },

        {
          path: 'handfeel',
          name: RouteName.CustomerKpiHandfeel,
          component: () => import('../views/CustomerDetails/CustomerCSHandfell.vue'),
          meta: {
            title: 'CS Handfeel',
          },
        },
      ],
    },
    {
      path: 'all-data',
      name: RouteName.CustomerAllData,
      component: () => import('@/views/CustomerDetails/CustomerAllData.vue'),
      redirect: { name: RouteName.CustomerProductFeatureUseData },
      children: [
        {
          path: 'ticketing',
          name: RouteName.CustomerTicketingData,
          component: () => import('@/views/CustomerDetails/CustomerTicketingData.vue'),
          meta: {
            title: 'Ticketing',
          },
        },
        {
          path: 'feature-request',
          name: RouteName.CustomerFeatureRequestData,
          component: () => import('@/views/CustomerDetails/CustomerFeatureRequestData.vue'),
          meta: {
            title: 'Feature-Request',
          },
        },
        {
          path: 'payment',
          name: RouteName.CustomerPaymentData,
          component: () => import('@/views/CustomerDetails/CustomerPaymentDataContainer.vue'),
          meta: {
            title: 'Payment',
          },
        },
        {
          path: 'email',
          name: RouteName.CustomerEmailData,
          component: () => import('@/views/CustomerDetails/CustomerEmailData.vue'),
          meta: {
            title: 'Email',
          },
        },
        {
          path: 'product-feature-data',
          name: RouteName.CustomerProductFeatureUseData,
          component: () => import('@/views/CustomerDetails/CustomerProductFeatureData.vue'),
          meta: {
            title: 'Product Feature',
          },
        },
        {
          path: 'communication-systems',
          name: RouteName.CustomerCommSystemsData,
          component: () => import('@/views/CustomerDetails/CustomerCommSystemsData.vue'),
          meta: {
            title: 'Communication Systems',
          },
        },
        {
          path: 'api-data',
          name: RouteName.CustomerApiData,
          component: () => import('@/views/CustomerDetails/CustomerApiData.vue'),
          meta: {
            title: 'API Data',
          },
        },
      ],
    },
    {
      path: 'tasks',
      name: RouteName.CustomerTasks,
      component: () => import('../views/CustomerDetails/CustomerTaskContainer.vue'),
      meta: {
        title: 'Tasks',
      },
    },
    {
      path: 'messages',
      name: RouteName.CustomerMessages,
      component: () => import('../views/CustomerDetails/CustomerMessageContainer.vue'),
      meta: {
        title: 'Messages',
      },
    },
    {
      path: 'actions',
      name: RouteName.CustomerActions,
      component: () => import('../views/CustomerDetails/CustomerActionContainer.vue'),
      meta: {
        title: 'Actions',
      },
    },
    {
      path: 'success-plans',
      name: RouteName.CustomerSuccessPlans,
      component: () => import('../views/CustomerDetails/CustomerSuccessPlans.vue'),
      meta: {
        title: 'Success Plans',
      },
    },
    {
      path: 'insights',
      name: RouteName.CustomerInsights,
      component: () => import('../views/CustomerDetails/CustomerInsights.vue'),
      meta: {
        title: 'Insights',
      },
    },

    {
      path: 'documents',
      name: RouteName.CustomerDocuments,
      component: () => import('../views/CustomerDetails/CustomerDocuments.vue'),
      meta: {
        title: 'Documents',
      },
    },
    {
      path: 'notes',
      name: RouteName.CustomerNotes,
      component: () => import('../views/CustomerDetails/CustomerNotes.vue'),
      meta: {
        title: 'Notes',
      },
    },
    {
      path: 'upsell-deals',
      name: RouteName.CustomerUpsellDeals,
      component: () => import('../views/CustomerDetails/CustomerUpsellDeals.vue'),
      meta: {
        title: 'Upsell Deals',
      },
    },
    {
      path: 'renewal-deals',
      name: RouteName.CustomerRenewalDeals,
      component: () => import('../views/CustomerDetails/CustomerRenewalDeals.vue'),
      meta: {
        title: 'Renewal Deals',
      },
    },
  ],
};
