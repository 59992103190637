import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';
import { Layout } from '@/types/layout';

export const InboxRoutes: RouteRecordRaw[] = [
  {
    path: '/inbox',
    name: RouteName.InboxList,
    component: () => import('@/views/Inbox/InboxListView.vue'),
    meta: {
      title: 'Inbox',
      group: RouteName.InboxList,
      layout: Layout.Authenticated,
    },
  },
  {
    path: '/inbox/:id/consent',
    name: RouteName.InboxConsent,
    component: () => import('@/views/Inbox/InboxConsentView.vue'),
    meta: {
      title: 'Inbox',
      group: RouteName.Inbox,
      layout: Layout.Authenticated,
    },
  },
  {
    path: '/inbox/:id',
    name: RouteName.Inbox,
    component: () => import('@/views/Inbox/InboxView.vue'),
    meta: {
      title: 'Inbox',
      group: RouteName.InboxList,
      layout: Layout.Authenticated,
    },
    children: [
      {
        path: 'l/:labelId',
        name: RouteName.InboxThreadList,
        component: () => import('@/views/Inbox/InboxThreadListView.vue'),
        children: [
          {
            path: 't/:threadId',
            name: RouteName.InboxThread,
            component: () => import('@/views/Inbox/InboxThreadView.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/inbox/connect',
    name: RouteName.InboxConnect,
    component: () => import('@/views/Inbox/InboxConnectView.vue'),
    meta: {
      title: 'Inbox',
      group: RouteName.InboxList,
      layout: Layout.Authenticated,
    },
  },
  {
    path: '/inbox/connect/:provider/new/',
    name: RouteName.InboxConnectNew,
    component: () => import('@/views/Inbox/InboxConnectNewView.vue'),
    props: true,
    meta: {
      title: 'Inbox',
      group: RouteName.InboxList,
      layout: Layout.Authenticated,
    },
  },
  {
    path: '/inbox/settings/oauth2/:provider/verify',
    name: RouteName.InboxOAuth2Verify,
    component: () => import('@/views/Inbox/InboxOAuth2VerifyView.vue'),
    props: true,
  },
  {
    path: '/inbox/settings',
    name: RouteName.InboxSettings,
    component: () => import('@/views/Inbox/InboxSettingsView.vue'),
    meta: {
      title: 'Inbox',
      group: RouteName.InboxList,
      layout: Layout.Authenticated,
    },
  },
];
