<script setup lang="ts">
import { phMagnifyingGlass } from '@/phosphor-icons';
import { RouteName } from '@/router/route-name';
import { useRouteQuery } from '@vueuse/router';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const searchQuery = useRouteQuery<string>('search');
const q = ref('');

const onSubmit = () => {
  q.value = q.value.replace(/[^\w\s]/gi, '');
  if (!q.value.trim()) {
    return;
  }
  const fromPath = route.query.from ? route.query.from : route.fullPath;
  const type = route.query.type ? route.query.type : 'customers';
  if (type === 'inbox') {
    router.push({ name: RouteName.Search, query: { search: q.value, type: type, from: fromPath, page: 1 } });
  } else {
    router.push({ name: RouteName.Search, query: { search: q.value, type: type, from: fromPath } });
  }
};

const search = computed({
  get() {
    return searchQuery.value;
  },
  set(value: string) {
    q.value = value;
  },
});
</script>

<template>
  <form @submit.prevent="onSubmit">
    <q-input
      v-model="search"
      placeholder="Search..."
      class="tw-rounded-3xl tw-border tw-px-4"
      :ripple="false"
      dense
      borderless
    >
      <template v-slot:append>
        <button type="submit" @click.prevent="onSubmit">
          <q-icon :name="phMagnifyingGlass" class="tw-h-4 tw-w-4" />
        </button>
      </template>
    </q-input>
  </form>
</template>
