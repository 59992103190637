/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2SuccessPlanLogFilterDto } from '../model/';
// @ts-ignore
import { C2SuccessPlansLog } from '../model/';
// @ts-ignore
import { RoleBaseSuccessPlanLogPaginatedDto } from '../model/';
// @ts-ignore
import { SuccessPlanPaginationDto } from '../model/';
// @ts-ignore
import { SuccessRateInfoDto } from '../model/';
// @ts-ignore
import { UpdateC2SuccessPlanLogDto } from '../model/';
/**
 * C2SuccessPlanLogApi - axios parameter creator
 * @export
 */
export const C2SuccessPlanLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Zapbook Success Plan Details CSV Download
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv: async (c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2SuccessPlanLogFilterDto' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv', 'c2SuccessPlanLogFilterDto', c2SuccessPlanLogFilterDto)
            const localVarPath = `/api/c2-success-plan-log/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2SuccessPlanLogFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of c2 success plan logs
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindAll: async (c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2SuccessPlanLogFilterDto' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindAll', 'c2SuccessPlanLogFilterDto', c2SuccessPlanLogFilterDto)
            const localVarPath = `/api/c2-success-plan-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2SuccessPlanLogFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of success plan logs with same uuid
         * @param {string} uuid 
         * @param {string} [xTenantId] 
         * @param {string} [successPlanId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindAllByUUID: async (uuid: string, xTenantId?: string, successPlanId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindAllByUUID', 'uuid', uuid)
            const localVarPath = `/api/c2-success-plan-log/uuid/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (successPlanId !== undefined) {
                localVarQueryParameter['success_plan_id'] = successPlanId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find number of c2 success plan log entries
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindCount: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindCount', 'id', id)
            const localVarPath = `/api/c2-success-plan-log/success-plan-log-count/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find c2 success plan log against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindOne', 'id', id)
            const localVarPath = `/api/c2-success-plan-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find ongoing success plan entries in success plan log for this success plan id
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries: async (successPlanId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'successPlanId' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries', 'successPlanId', successPlanId)
            const localVarPath = `/api/c2-success-plan-log/ongoing_success_rate_log_entry/{success_plan_id}`
                .replace(`{${"success_plan_id"}}`, encodeURIComponent(String(successPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch c2 success plan log action count
         * @param {string} activeU1Id Mandatory field
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindSuccessPlanActionCount: async (activeU1Id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeU1Id' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindSuccessPlanActionCount', 'activeU1Id', activeU1Id)
            const localVarPath = `/api/c2-success-plan-log/action-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeU1Id !== undefined) {
                localVarQueryParameter['active_u1_id'] = activeU1Id;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find completed success plan entries in success plan log for this success plan id
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindSuccessPlanLogEntries: async (successPlanId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'successPlanId' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindSuccessPlanLogEntries', 'successPlanId', successPlanId)
            const localVarPath = `/api/c2-success-plan-log/success_rate_log_entry/{success_plan_id}`
                .replace(`{${"success_plan_id"}}`, encodeURIComponent(String(successPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find success rate for success plan
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindSuccessRate: async (successPlanId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'successPlanId' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerFindSuccessRate', 'successPlanId', successPlanId)
            const localVarPath = `/api/c2-success-plan-log/success_rate/{success_plan_id}`
                .replace(`{${"success_plan_id"}}`, encodeURIComponent(String(successPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a action comment by action id
         * @param {string} id 
         * @param {UpdateC2SuccessPlanLogDto} updateC2SuccessPlanLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerUpdate: async (id: string, updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerUpdate', 'id', id)
            // verify required parameter 'updateC2SuccessPlanLogDto' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerUpdate', 'updateC2SuccessPlanLogDto', updateC2SuccessPlanLogDto)
            const localVarPath = `/api/c2-success-plan-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2SuccessPlanLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a action comment by action id V2
         * @param {string} id 
         * @param {UpdateC2SuccessPlanLogDto} updateC2SuccessPlanLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerUpdateV2: async (id: string, updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerUpdateV2', 'id', id)
            // verify required parameter 'updateC2SuccessPlanLogDto' is not null or undefined
            assertParamExists('c2SuccessPlansLogControllerUpdateV2', 'updateC2SuccessPlanLogDto', updateC2SuccessPlanLogDto)
            const localVarPath = `/api/v2/c2-success-plan-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateC2SuccessPlanLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2SuccessPlanLogApi - functional programming interface
 * @export
 */
export const C2SuccessPlanLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2SuccessPlanLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Zapbook Success Plan Details CSV Download
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv(c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv(c2SuccessPlanLogFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of c2 success plan logs
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindAll(c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseSuccessPlanLogPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindAll(c2SuccessPlanLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of success plan logs with same uuid
         * @param {string} uuid 
         * @param {string} [xTenantId] 
         * @param {string} [successPlanId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindAllByUUID(uuid: string, xTenantId?: string, successPlanId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2SuccessPlansLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindAllByUUID(uuid, xTenantId, successPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find number of c2 success plan log entries
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindCount(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindCount(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find c2 success plan log against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2SuccessPlansLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find ongoing success plan entries in success plan log for this success plan id
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries(successPlanId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlanPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries(successPlanId, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch c2 success plan log action count
         * @param {string} activeU1Id Mandatory field
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindSuccessPlanActionCount(activeU1Id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindSuccessPlanActionCount(activeU1Id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find completed success plan entries in success plan log for this success plan id
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindSuccessPlanLogEntries(successPlanId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessPlanPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindSuccessPlanLogEntries(successPlanId, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find success rate for success plan
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerFindSuccessRate(successPlanId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRateInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerFindSuccessRate(successPlanId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a action comment by action id
         * @param {string} id 
         * @param {UpdateC2SuccessPlanLogDto} updateC2SuccessPlanLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerUpdate(id: string, updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerUpdate(id, updateC2SuccessPlanLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a action comment by action id V2
         * @param {string} id 
         * @param {UpdateC2SuccessPlanLogDto} updateC2SuccessPlanLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SuccessPlansLogControllerUpdateV2(id: string, updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SuccessPlansLogControllerUpdateV2(id, updateC2SuccessPlanLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2SuccessPlanLogApi - factory interface
 * @export
 */
export const C2SuccessPlanLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2SuccessPlanLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Zapbook Success Plan Details CSV Download
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv(c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv(c2SuccessPlanLogFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of c2 success plan logs
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindAll(c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseSuccessPlanLogPaginatedDto> {
            return localVarFp.c2SuccessPlansLogControllerFindAll(c2SuccessPlanLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of success plan logs with same uuid
         * @param {string} uuid 
         * @param {string} [xTenantId] 
         * @param {string} [successPlanId] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindAllByUUID(uuid: string, xTenantId?: string, successPlanId?: string, options?: any): AxiosPromise<Array<C2SuccessPlansLog>> {
            return localVarFp.c2SuccessPlansLogControllerFindAllByUUID(uuid, xTenantId, successPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find number of c2 success plan log entries
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindCount(id: string, xTenantId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.c2SuccessPlansLogControllerFindCount(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find c2 success plan log against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<C2SuccessPlansLog> {
            return localVarFp.c2SuccessPlansLogControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find ongoing success plan entries in success plan log for this success plan id
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries(successPlanId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<SuccessPlanPaginationDto> {
            return localVarFp.c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries(successPlanId, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch c2 success plan log action count
         * @param {string} activeU1Id Mandatory field
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindSuccessPlanActionCount(activeU1Id: string, xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.c2SuccessPlansLogControllerFindSuccessPlanActionCount(activeU1Id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find completed success plan entries in success plan log for this success plan id
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindSuccessPlanLogEntries(successPlanId: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<SuccessPlanPaginationDto> {
            return localVarFp.c2SuccessPlansLogControllerFindSuccessPlanLogEntries(successPlanId, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find success rate for success plan
         * @param {string} successPlanId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerFindSuccessRate(successPlanId: string, xTenantId?: string, options?: any): AxiosPromise<SuccessRateInfoDto> {
            return localVarFp.c2SuccessPlansLogControllerFindSuccessRate(successPlanId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a action comment by action id
         * @param {string} id 
         * @param {UpdateC2SuccessPlanLogDto} updateC2SuccessPlanLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerUpdate(id: string, updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.c2SuccessPlansLogControllerUpdate(id, updateC2SuccessPlanLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a action comment by action id V2
         * @param {string} id 
         * @param {UpdateC2SuccessPlanLogDto} updateC2SuccessPlanLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SuccessPlansLogControllerUpdateV2(id: string, updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.c2SuccessPlansLogControllerUpdateV2(id, updateC2SuccessPlanLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsvRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsvRequest {
    /**
     * 
     * @type {C2SuccessPlanLogFilterDto}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsv
     */
    readonly c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindAll operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllRequest {
    /**
     * 
     * @type {C2SuccessPlanLogFilterDto}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAll
     */
    readonly after?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindAllByUUID operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUIDRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUIDRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUID
     */
    readonly uuid: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUID
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUID
     */
    readonly successPlanId?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindCount operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindCountRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindCountRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindCount
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindOne operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOneRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntriesRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntriesRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly successPlanId: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries
     */
    readonly after?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindSuccessPlanActionCount operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanActionCountRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanActionCountRequest {
    /**
     * Mandatory field
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanActionCount
     */
    readonly activeU1Id: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanActionCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindSuccessPlanLogEntries operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntriesRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntriesRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly successPlanId: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntries
     */
    readonly after?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerFindSuccessRate operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessRateRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessRateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessRate
     */
    readonly successPlanId: string

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessRate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerUpdate operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateRequest
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2SuccessPlanLogDto}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdate
     */
    readonly updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SuccessPlansLogControllerUpdateV2 operation in C2SuccessPlanLogApi.
 * @export
 * @interface C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2Request
 */
export interface C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2
     */
    readonly id: string

    /**
     * 
     * @type {UpdateC2SuccessPlanLogDto}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2
     */
    readonly updateC2SuccessPlanLogDto: UpdateC2SuccessPlanLogDto

    /**
     * 
     * @type {string}
     * @memberof C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2
     */
    readonly xTenantId?: string
}

/**
 * C2SuccessPlanLogApi - object-oriented interface
 * @export
 * @class C2SuccessPlanLogApi
 * @extends {BaseAPI}
 */
export class C2SuccessPlanLogApi extends BaseAPI {
    /**
     * 
     * @summary Zapbook Success Plan Details CSV Download
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerDownloadSuccessPlanDataCsvRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerDownloadSuccessPlanDataCsv(requestParameters.c2SuccessPlanLogFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of c2 success plan logs
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindAll(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindAll(requestParameters.c2SuccessPlanLogFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of success plan logs with same uuid
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindAllByUUID(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindAllByUUIDRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindAllByUUID(requestParameters.uuid, requestParameters.xTenantId, requestParameters.successPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find number of c2 success plan log entries
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindCount(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindCountRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindCount(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find c2 success plan log against ID
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindOne(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOneRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find ongoing success plan entries in success plan log for this success plan id
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntriesRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindOngoingSuccessPlanLogEntries(requestParameters.successPlanId, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch c2 success plan log action count
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanActionCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindSuccessPlanActionCount(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanActionCountRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindSuccessPlanActionCount(requestParameters.activeU1Id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find completed success plan entries in success plan log for this success plan id
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindSuccessPlanLogEntries(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessPlanLogEntriesRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindSuccessPlanLogEntries(requestParameters.successPlanId, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find success rate for success plan
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerFindSuccessRate(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerFindSuccessRateRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerFindSuccessRate(requestParameters.successPlanId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a action comment by action id
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerUpdate(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateRequest, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerUpdate(requestParameters.id, requestParameters.updateC2SuccessPlanLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a action comment by action id V2
     * @param {C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SuccessPlanLogApi
     */
    public c2SuccessPlansLogControllerUpdateV2(requestParameters: C2SuccessPlanLogApiC2SuccessPlansLogControllerUpdateV2Request, options?: AxiosRequestConfig) {
        return C2SuccessPlanLogApiFp(this.configuration).c2SuccessPlansLogControllerUpdateV2(requestParameters.id, requestParameters.updateC2SuccessPlanLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
