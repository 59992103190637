import { RouteRecordRaw } from 'vue-router';
import { RouteName } from './route-name';
import { Layout } from '@/types/layout';

export const SurveyRoutes: RouteRecordRaw[] = [
  {
    path: '/surveys',
    name: RouteName.Surveys,
    meta: {
      title: 'Surveys',
      group: RouteName.Surveys,
      layout: Layout.Authenticated,
    },
    component: () => import('@/views/Surveys/SurveysView.vue'),
    redirect: { name: RouteName.SurveysOverview },
    children: [
      {
        path: 'overview',
        name: RouteName.SurveysOverview,
        component: () => import('@/views/Surveys/SurveysOverview.vue'),
        meta: {
          title: 'Surveys Overview',
          group: RouteName.Surveys,
          layout: Layout.Authenticated,
        },
      },
      {
        path: 'nps',
        name: RouteName.NpsSurveys,
        meta: {
          title: 'NPS Surveys',
          group: RouteName.Surveys,
          layout: Layout.Authenticated,
        },
        component: () => import('@/views/Surveys/NpsSurveys.vue'),
      },
      {
        path: 'csat',
        name: RouteName.CsatSurveys,
        meta: {
          title: 'CSAT Surveys',
          group: RouteName.Surveys,
          layout: Layout.Authenticated,
        },
        component: () => import('@/views/Surveys/CsatSurveys.vue'),
      },
    ],
  },
  {
    path: '/surveys/new',
    name: RouteName.NewSurvey,
    meta: {
      title: 'Surveys',
      group: RouteName.Surveys,
      layout: Layout.Authenticated,
    },
    component: () => import('@/views/Surveys/NewSurveyView.vue'),
  },
  {
    path: '/surveys/:surveyId/edit',
    name: RouteName.EditSurvey,
    meta: {
      title: 'Surveys',
      group: RouteName.Surveys,
      layout: Layout.Authenticated,
    },
    component: () => import('@/views/Surveys/EditSurveyView.vue'),
  },
  {
    path: '/:c1MasterId/surveys/:surveyId/response',
    name: RouteName.SurveyResponse,
    meta: {
      title: 'Surveys',
      layout: Layout.Scratch,
      skipAuth: true,
    },
    component: () => import('@/views/Surveys/SurveyResponseView.vue'),
  },
];

export const NpsSurveyDetailsRoute: RouteRecordRaw = {
  path: '/surveys/nps/:id',
  name: RouteName.NpsSurvey,
  meta: {
    group: RouteName.Surveys,
    layout: Layout.Authenticated,
  },
  component: () => import('../views/Surveys/SurveyDetails.vue'),
  children: [],
};

export const CsatSurveyDetailsRoute: RouteRecordRaw = {
  path: '/surveys/csat/:id',
  name: RouteName.CsatSurvey,
  meta: {
    group: RouteName.Surveys,
    layout: Layout.Authenticated,
  },
  component: () => import('../views/Surveys/SurveyDetails.vue'),
  children: [],
};
