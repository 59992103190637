import { useAccountStore } from '@/stores/account';
import { useProductStore } from '@/stores/product';
import { useU1RoleStore } from '@/stores/u1Role';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { parse } from 'tldts';

export const useProbe = () => {
  const init = () => {
    const accountStore = useAccountStore();
    const { activeU1, activeC1Master } = storeToRefs(accountStore);
    const u1RolesStore = useU1RoleStore();
    const { u1Roles } = storeToRefs(u1RolesStore);
    const productStore = useProductStore();
    const { products } = storeToRefs(productStore);

    watch([activeU1, activeC1Master, u1Roles, products], ([newU1, newC1, newU1Roles, newProducts]) => {
      if (newU1 && newC1 && newU1Roles.length && newProducts.length) {
        const u1Role = newU1Roles.find((v) => v.id === newU1.role_id);
        const debug = import.meta.env.VITE_PROBE_DEBUG === '1';
        const internalUser = parse(newU1.email).domain === import.meta.env.VITE_ZAPSCALE_DOMAIN;
        const development = internalUser || import.meta.env.VITE_PROBE_DEVELOPMENT_MODE === '1';

        const zapscaleC1Id = import.meta.env.VITE_ZAPSCALE_C1_ID;
        const apiHost = import.meta.env.VITE_PROBE_API_HOST;

        if (zapscaleC1Id && apiHost) {
          window.zapscale?.init(
            zapscaleC1Id,
            {
              product_name: 'ZapScale',
              organization_id: newC1.id,
              organization_name: newC1.name,
              user_id: newU1.id,
              user_email: newU1.email,
              user_name: newU1.name,
              role_id: u1Role?.u1_role_app_id as string,
              role_name: u1Role?.name as string,
            },
            {
              api_host: apiHost,
              debug,
              development,
            },
          );
        }

        const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
        if (mixpanelToken && !internalUser) {
          window.mixpanel?.init(mixpanelToken, {
            debug: false,
            track_pageview: 'url-with-path',
            persistence: 'localStorage',
          });
          window.mixpanel.identify(newU1.id);

          window.mixpanel.people.set({
            $name: newU1.name,
            $email: newU1.email,
            product_name: 'ZapScale',
            organization_id: newC1.id,
            organization_name: newC1.name,
            role_id: u1Role?.u1_role_app_id as string,
            role_name: u1Role?.name as string,
          });
        }
      }
    });
  };

  return {
    init,
  };
};
