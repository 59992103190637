/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionFilterDto } from '../model/';
// @ts-ignore
import { C2SuccessPlanLogFilterDto } from '../model/';
// @ts-ignore
import { CustomerListFilterDto } from '../model/';
// @ts-ignore
import { MessageFilterV2Dto } from '../model/';
// @ts-ignore
import { TaskFilterDto } from '../model/';
// @ts-ignore
import { TicketTrackingFilterDto } from '../model/';
// @ts-ignore
import { UserListFilterDto } from '../model/';
/**
 * CsvDownloadJobApi - axios parameter creator
 * @export
 */
export const CsvDownloadJobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {boolean} isAllCheckboxSelected 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs: async (jobType: string, c2TypeId: string, isAllCheckboxSelected: boolean, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs', 'jobType', jobType)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs', 'c2TypeId', c2TypeId)
            // verify required parameter 'isAllCheckboxSelected' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs', 'isAllCheckboxSelected', isAllCheckboxSelected)
            const localVarPath = `/api/csv-download-job/all-custom-fields-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (isAllCheckboxSelected !== undefined) {
                localVarQueryParameter['isAllCheckboxSelected'] = isAllCheckboxSelected;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob: async (jobType: string, ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob', 'jobType', jobType)
            // verify required parameter 'ticketTrackingFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob', 'ticketTrackingFilterDto', ticketTrackingFilterDto)
            const localVarPath = `/api/csv-download-job/all-data-ticketing-list-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketTrackingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs: async (jobType: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs', 'jobType', jobType)
            const localVarPath = `/api/csv-download-job/assigned-csm-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueCsGutFeelDownloadJob: async (jobType: string, c2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCsGutFeelDownloadJob', 'jobType', jobType)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCsGutFeelDownloadJob', 'c2TypeId', c2TypeId)
            const localVarPath = `/api/csv-download-job/cs-gutFeel-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} customViewId Optional field
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob: async (jobType: string, customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob', 'jobType', jobType)
            // verify required parameter 'customViewId' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob', 'customViewId', customViewId)
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/csv-download-job/customer-list-custom-view-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (customViewId !== undefined) {
                localVarQueryParameter['customViewId'] = customViewId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob: async (jobType: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob', 'jobType', jobType)
            // verify required parameter 'customerListFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob', 'customerListFilterDto', customerListFilterDto)
            const localVarPath = `/api/csv-download-job/customer-list-default-view-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob: async (jobType: string, c2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob', 'jobType', jobType)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob', 'c2TypeId', c2TypeId)
            const localVarPath = `/api/csv-download-job/saas-customer-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} u2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueSaasUserDownloadJobs: async (jobType: string, u2TypeId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSaasUserDownloadJobs', 'jobType', jobType)
            // verify required parameter 'u2TypeId' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSaasUserDownloadJobs', 'u2TypeId', u2TypeId)
            const localVarPath = `/api/csv-download-job/saas-user-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (u2TypeId !== undefined) {
                localVarQueryParameter['u2TypeId'] = u2TypeId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {Array<string>} selectedCustomFieldNames 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs: async (jobType: string, c2TypeId: string, selectedCustomFieldNames: Array<string>, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs', 'jobType', jobType)
            // verify required parameter 'c2TypeId' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs', 'c2TypeId', c2TypeId)
            // verify required parameter 'selectedCustomFieldNames' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs', 'selectedCustomFieldNames', selectedCustomFieldNames)
            const localVarPath = `/api/csv-download-job/selected-custom-fields-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (c2TypeId !== undefined) {
                localVarQueryParameter['c2TypeId'] = c2TypeId;
            }

            if (selectedCustomFieldNames) {
                localVarQueryParameter['selectedCustomFieldNames'] = selectedCustomFieldNames;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob: async (jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob', 'descending', descending)
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/csv-download-job/from-customer-details-task-info-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob: async (jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob', 'descending', descending)
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/csv-download-job/from-user-details-task-list-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueUserListDownloadJob: async (jobType: string, sortBy: string, descending: boolean, userListFilterDto: UserListFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueUserListDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueUserListDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueUserListDownloadJob', 'descending', descending)
            // verify required parameter 'userListFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueUserListDownloadJob', 'userListFilterDto', userListFilterDto)
            const localVarPath = `/api/csv-download-job/user-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookActionDownloadJob: async (jobType: string, sortBy: string, descending: boolean, actionFilterDto: ActionFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookActionDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookActionDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookActionDownloadJob', 'descending', descending)
            // verify required parameter 'actionFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookActionDownloadJob', 'actionFilterDto', actionFilterDto)
            const localVarPath = `/api/csv-download-job/zapbook-action-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookMessageDownloadJob: async (jobType: string, sortBy: string, descending: boolean, messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookMessageDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookMessageDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookMessageDownloadJob', 'descending', descending)
            // verify required parameter 'messageFilterV2Dto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookMessageDownloadJob', 'messageFilterV2Dto', messageFilterV2Dto)
            const localVarPath = `/api/csv-download-job/zapbook-message-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFilterV2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob: async (jobType: string, sortBy: string, descending: boolean, c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob', 'descending', descending)
            // verify required parameter 'c2SuccessPlanLogFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob', 'c2SuccessPlanLogFilterDto', c2SuccessPlanLogFilterDto)
            const localVarPath = `/api/csv-download-job/zapbook-success-plan-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2SuccessPlanLogFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookTaskDownloadJob: async (jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobType' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookTaskDownloadJob', 'jobType', jobType)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookTaskDownloadJob', 'sortBy', sortBy)
            // verify required parameter 'descending' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookTaskDownloadJob', 'descending', descending)
            // verify required parameter 'taskFilterDto' is not null or undefined
            assertParamExists('csvDownloadJobControllerEnqueueZapbookTaskDownloadJob', 'taskFilterDto', taskFilterDto)
            const localVarPath = `/api/csv-download-job/zapbook-task-download-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CsvDownloadJobApi - functional programming interface
 * @export
 */
export const CsvDownloadJobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CsvDownloadJobApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {boolean} isAllCheckboxSelected 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs(jobType: string, c2TypeId: string, isAllCheckboxSelected: boolean, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs(jobType, c2TypeId, isAllCheckboxSelected, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob(jobType: string, ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob(jobType, ticketTrackingFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs(jobType: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs(jobType, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueCsGutFeelDownloadJob(jobType: string, c2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueCsGutFeelDownloadJob(jobType, c2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} customViewId Optional field
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob(jobType: string, customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob(jobType, customViewId, customerListFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob(jobType: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob(jobType, customerListFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob(jobType: string, c2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob(jobType, c2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} u2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueSaasUserDownloadJobs(jobType: string, u2TypeId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueSaasUserDownloadJobs(jobType, u2TypeId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {Array<string>} selectedCustomFieldNames 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs(jobType: string, c2TypeId: string, selectedCustomFieldNames: Array<string>, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs(jobType, c2TypeId, selectedCustomFieldNames, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob(jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob(jobType, sortBy, descending, taskFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob(jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob(jobType, sortBy, descending, taskFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueUserListDownloadJob(jobType: string, sortBy: string, descending: boolean, userListFilterDto: UserListFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueUserListDownloadJob(jobType, sortBy, descending, userListFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueZapbookActionDownloadJob(jobType: string, sortBy: string, descending: boolean, actionFilterDto: ActionFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueZapbookActionDownloadJob(jobType, sortBy, descending, actionFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueZapbookMessageDownloadJob(jobType: string, sortBy: string, descending: boolean, messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueZapbookMessageDownloadJob(jobType, sortBy, descending, messageFilterV2Dto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob(jobType: string, sortBy: string, descending: boolean, c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob(jobType, sortBy, descending, c2SuccessPlanLogFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async csvDownloadJobControllerEnqueueZapbookTaskDownloadJob(jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.csvDownloadJobControllerEnqueueZapbookTaskDownloadJob(jobType, sortBy, descending, taskFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CsvDownloadJobApi - factory interface
 * @export
 */
export const CsvDownloadJobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CsvDownloadJobApiFp(configuration)
    return {
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {boolean} isAllCheckboxSelected 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs(jobType: string, c2TypeId: string, isAllCheckboxSelected: boolean, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs(jobType, c2TypeId, isAllCheckboxSelected, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {TicketTrackingFilterDto} ticketTrackingFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob(jobType: string, ticketTrackingFilterDto: TicketTrackingFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob(jobType, ticketTrackingFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs(jobType: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs(jobType, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueCsGutFeelDownloadJob(jobType: string, c2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueCsGutFeelDownloadJob(jobType, c2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} customViewId Optional field
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob(jobType: string, customViewId: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob(jobType, customViewId, customerListFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {CustomerListFilterDto} customerListFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob(jobType: string, customerListFilterDto: CustomerListFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob(jobType, customerListFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob(jobType: string, c2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob(jobType, c2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} u2TypeId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueSaasUserDownloadJobs(jobType: string, u2TypeId: string, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueSaasUserDownloadJobs(jobType, u2TypeId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} c2TypeId 
         * @param {Array<string>} selectedCustomFieldNames 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs(jobType: string, c2TypeId: string, selectedCustomFieldNames: Array<string>, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs(jobType, c2TypeId, selectedCustomFieldNames, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob(jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob(jobType, sortBy, descending, taskFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob(jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob(jobType, sortBy, descending, taskFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {UserListFilterDto} userListFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueUserListDownloadJob(jobType: string, sortBy: string, descending: boolean, userListFilterDto: UserListFilterDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueUserListDownloadJob(jobType, sortBy, descending, userListFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookActionDownloadJob(jobType: string, sortBy: string, descending: boolean, actionFilterDto: ActionFilterDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueZapbookActionDownloadJob(jobType, sortBy, descending, actionFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {MessageFilterV2Dto} messageFilterV2Dto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookMessageDownloadJob(jobType: string, sortBy: string, descending: boolean, messageFilterV2Dto: MessageFilterV2Dto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueZapbookMessageDownloadJob(jobType, sortBy, descending, messageFilterV2Dto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {C2SuccessPlanLogFilterDto} c2SuccessPlanLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob(jobType: string, sortBy: string, descending: boolean, c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob(jobType, sortBy, descending, c2SuccessPlanLogFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobType 
         * @param {string} sortBy 
         * @param {boolean} descending 
         * @param {TaskFilterDto} taskFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        csvDownloadJobControllerEnqueueZapbookTaskDownloadJob(jobType: string, sortBy: string, descending: boolean, taskFilterDto: TaskFilterDto, xTenantId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.csvDownloadJobControllerEnqueueZapbookTaskDownloadJob(jobType, sortBy, descending, taskFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobsRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs
     */
    readonly isAllCheckboxSelected: boolean

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {TicketTrackingFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob
     */
    readonly ticketTrackingFilterDto: TicketTrackingFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob
     */
    readonly descending?: boolean
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobsRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueCsGutFeelDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJob
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob
     */
    readonly jobType: string

    /**
     * Optional field
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob
     */
    readonly customViewId: string

    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob
     */
    readonly descending?: boolean
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {CustomerListFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob
     */
    readonly customerListFilterDto: CustomerListFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob
     */
    readonly descending?: boolean
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJob
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueSaasUserDownloadJobs operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobsRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobs
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobs
     */
    readonly u2TypeId: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobs
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobsRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs
     */
    readonly c2TypeId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs
     */
    readonly selectedCustomFieldNames: Array<string>

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {TaskFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {TaskFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueUserListDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {UserListFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJob
     */
    readonly userListFilterDto: UserListFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueZapbookActionDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {ActionFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJob
     */
    readonly actionFilterDto: ActionFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueZapbookMessageDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {MessageFilterV2Dto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJob
     */
    readonly messageFilterV2Dto: MessageFilterV2Dto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {C2SuccessPlanLogFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob
     */
    readonly c2SuccessPlanLogFilterDto: C2SuccessPlanLogFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for csvDownloadJobControllerEnqueueZapbookTaskDownloadJob operation in CsvDownloadJobApi.
 * @export
 * @interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJobRequest
 */
export interface CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJob
     */
    readonly jobType: string

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJob
     */
    readonly sortBy: string

    /**
     * 
     * @type {boolean}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJob
     */
    readonly descending: boolean

    /**
     * 
     * @type {TaskFilterDto}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJob
     */
    readonly taskFilterDto: TaskFilterDto

    /**
     * 
     * @type {string}
     * @memberof CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJob
     */
    readonly xTenantId?: string
}

/**
 * CsvDownloadJobApi - object-oriented interface
 * @export
 * @class CsvDownloadJobApi
 * @extends {BaseAPI}
 */
export class CsvDownloadJobApi extends BaseAPI {
    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobsRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueAllCustomFieldsDownloadJobs(requestParameters.jobType, requestParameters.c2TypeId, requestParameters.isAllCheckboxSelected, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueAllDataTicketingListDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueAllDataTicketingListDownloadJob(requestParameters.jobType, requestParameters.ticketTrackingFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobsRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueAssignedCsmInfoDownloadJobs(requestParameters.jobType, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueCsGutFeelDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueCsGutFeelDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueCsGutFeelDownloadJob(requestParameters.jobType, requestParameters.c2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueCustomerListForCustomViewDownloadJob(requestParameters.jobType, requestParameters.customViewId, requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueCustomerListForDefaultViewDownloadJob(requestParameters.jobType, requestParameters.customerListFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasCustomerListDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueSaasCustomerListDownloadJob(requestParameters.jobType, requestParameters.c2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueSaasUserDownloadJobs(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueSaasUserDownloadJobsRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueSaasUserDownloadJobs(requestParameters.jobType, requestParameters.u2TypeId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobsRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueSelectedCustomFieldsDownloadJobs(requestParameters.jobType, requestParameters.c2TypeId, requestParameters.selectedCustomFieldNames, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueTaskInfoFromCustomerDetailsDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.taskFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueTaskInfoFromUserDetailsDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.taskFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueUserListDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueUserListDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueUserListDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.userListFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueZapbookActionDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookActionDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueZapbookActionDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.actionFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueZapbookMessageDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookMessageDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueZapbookMessageDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.messageFilterV2Dto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueZapbookSuccessPlanDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.c2SuccessPlanLogFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvDownloadJobApi
     */
    public csvDownloadJobControllerEnqueueZapbookTaskDownloadJob(requestParameters: CsvDownloadJobApiCsvDownloadJobControllerEnqueueZapbookTaskDownloadJobRequest, options?: AxiosRequestConfig) {
        return CsvDownloadJobApiFp(this.configuration).csvDownloadJobControllerEnqueueZapbookTaskDownloadJob(requestParameters.jobType, requestParameters.sortBy, requestParameters.descending, requestParameters.taskFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
