import { appTypeApi, c1MasterApi, peripheralAppApi, processorJobsApi, ServerError } from '@/api';
import {
  AppType,
  C1EmailIntegrationUpdateDto,
  C1Master,
  PeripheralApp,
  ProcessorValidationJobDto,
  UpdatePeripheralAppDto,
} from '@/api/client';
import {
  IAppsForAutoIntegrationCallback,
  PeripheralAppType,
  UpdatePeripheralAppCallback,
} from '@/types/peripheralapps.type';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { useAccountStore } from './account';
import axios, { AxiosError } from 'axios';

export const usePeripheralAppsStore = defineStore('peripheral-apps', () => {
  const { t } = useI18n<{ message: MessageSchema }>({
    useScope: 'global',
  });
  const isLoadingAppTypes = ref<boolean>(false);
  const appTypes = ref<AppType[]>([]);

  const isLoadingPeripheralApps = ref<boolean>(false);
  const peripheralApps = ref<PeripheralApp[]>([]);

  const selectedCrmAppName = ref<string>('');
  const selectedBugTrackingAppName = ref<string>('');
  const selectedEmailSystemAppName = ref<string>('');
  const selectedFeatureRequestAppName = ref<string>('');
  const selectedPaymentSystemAppName = ref<string>('');

  const accountStore = useAccountStore();
  const activeC1Master = ref<C1Master>();

  const isFirstLoad = ref<boolean>(true);

  const isLoading = computed(() => {
    return isLoadingAppTypes.value || isLoadingPeripheralApps.value;
  });

  async function fetchAppTypes() {
    if (appTypes.value.length > 0) {
      return appTypes;
    }
    isLoadingAppTypes.value = true;
    try {
      const { data } = await appTypeApi.appTypesControllerFindAll();
      appTypes.value = data;
    } catch (e) {
      console.error('App Types List', e);
    } finally {
      isLoadingAppTypes.value = false;
    }
  }

  async function fetchPeripheralApps(isForceUpdate?: boolean) {
    if (peripheralApps.value.length > 0 && !isForceUpdate) {
      return peripheralApps;
    }
    isLoadingPeripheralApps.value = true;
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAllWithSyncDate();
      peripheralApps.value = data;
      setIntegratedAppNamesForTypes();
    } catch (e) {
      console.error('Peripheral Apps List', e);
    } finally {
      isLoadingPeripheralApps.value = false;
    }
  }

  function setIntegratedAppNamesForTypes() {
    if (appTypes.value.length > 0 && peripheralApps.value.length > 0) {
      const integratedApps = peripheralApps.value.filter((x) => x.on_off_flag);
      if (integratedApps.length > 0) {
        selectedCrmAppName.value =
          integratedApps.find(
            (x) => x.app_type_id === appTypes.value.find((x) => x.name === PeripheralAppType.CRM.type)?.id || '',
          )?.name || '';

        selectedBugTrackingAppName.value =
          integratedApps.find(
            (x) =>
              x.app_type_id === appTypes.value.find((x) => x.name === PeripheralAppType.BUG_TRACKING.type)?.id || '',
          )?.name || '';

        selectedEmailSystemAppName.value =
          integratedApps.find(
            (x) =>
              x.app_type_id === appTypes.value.find((x) => x.name === PeripheralAppType.EMAIL_SYSTEM.type)?.id || '',
          )?.name || '';

        selectedFeatureRequestAppName.value =
          integratedApps.find(
            (x) =>
              x.app_type_id === appTypes.value.find((x) => x.name === PeripheralAppType.FEATURE_REQUESTS.type)?.id ||
              '',
          )?.name || '';

        selectedPaymentSystemAppName.value =
          integratedApps.find(
            (x) =>
              x.app_type_id === appTypes.value.find((x) => x.name === PeripheralAppType.PAYMENT_SYSTEM.type)?.id || '',
          )?.name || '';
      } else {
        selectedCrmAppName.value = '';
        selectedBugTrackingAppName.value = '';
        selectedEmailSystemAppName.value = '';
        selectedFeatureRequestAppName.value = '';
        selectedPaymentSystemAppName.value = '';
      }
    }
  }

  const isUpdatingAppStatus = ref<boolean>(false);

  async function updatePeripheralAppStatus(selectedApp: PeripheralApp, callback: UpdatePeripheralAppCallback) {
    const peripheralAppUpdateDto = {
      name: selectedApp.name,
      on_off_flag: selectedApp.on_off_flag,
      is_u1_level: selectedApp.is_u1_level,
    } as UpdatePeripheralAppDto;

    try {
      isUpdatingAppStatus.value = true;
      const { data } = await peripheralAppApi.peripheralAppsControllerIntegrate({
        id: selectedApp.id,
        updatePeripheralAppDto: peripheralAppUpdateDto,
      });
      if (data) {
        await fetchPeripheralApps(true);
        callback(true, t('peripheral_app_status_update_success'));
      } else {
        console.log('No data');
        callback(false, t('something_went_wrong'));
      }
    } catch (e) {
      console.log('Catch block');
      callback(false, t('something_went_wrong'));
      console.error('Peripheral App Update Error', e);
    } finally {
      isUpdatingAppStatus.value = false;
    }
  }

  async function getCurrentC1Master() {
    if (accountStore.activeC1Master && accountStore.activeC1Master.id) {
      try {
        const { data } = await c1MasterApi.c1MasterControllerFindOne({ id: accountStore.activeC1Master.id });
        if (data) {
          activeC1Master.value = data;
        }
      } catch (e) {
        console.error('Active C1Master Error', e);
      }
    }
  }

  async function updateC1MasterEmailIntegrationData(
    c1EmailUpdateDto: C1EmailIntegrationUpdateDto,
    callback: (status: boolean) => void,
  ) {
    if (!accountStore.activeC1Master || !accountStore.activeC1Master.id) return;
    try {
      isUpdatingAppStatus.value = true;
      const { data } = await c1MasterApi.c1MasterControllerUpdatePeripheralEmail({
        id: accountStore.activeC1Master.id,
        c1EmailIntegrationUpdateDto: c1EmailUpdateDto,
      });
      if (data) {
        activeC1Master.value = data;
        callback(true);
      } else {
        callback(false);
      }
    } catch (e) {
      console.error('Active C1Master Error', e);
    } finally {
      isUpdatingAppStatus.value = false;
    }
  }

  async function checkIsEmailAvailable(emailId: string) {
    let result = false;
    try {
      isUpdatingAppStatus.value = true;
      const { data } = await c1MasterApi.c1MasterControllerIsEmailAvailable({ peripheralEmail: emailId });
      result = data;
    } catch (e) {
      console.error('Email Check Error', e);
    } finally {
      isUpdatingAppStatus.value = false;
    }
    return result;
  }

  async function getAppsForAutoIntegration(linkedAppNames: string[], callback: IAppsForAutoIntegrationCallback) {
    try {
      const { data } = await peripheralAppApi.peripheralAppsControllerFindAppsForAutoIntegration({
        linkedApps: linkedAppNames,
      });
      callback(data, true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const err = e as AxiosError<ServerError>;
        callback([], false, err.response?.data.message || t('something_went_wrong'));
      } else {
        callback([], false, t('something_went_wrong'));
      }
      console.error('Auto integration apps fetch failed', e);
    }
  }

  const initiateValidationJob = (appName: string) => {
    processorJobsApi.processorJobsControllerExecuteTransformationValidationJob({
      processorValidationJobDto: { app_name: appName } as ProcessorValidationJobDto,
    });
  };

  return {
    isLoading,
    appTypes,
    peripheralApps,
    fetchAppTypes,
    fetchPeripheralApps,
    selectedCrmAppName,
    selectedBugTrackingAppName,
    selectedEmailSystemAppName,
    selectedFeatureRequestAppName,
    selectedPaymentSystemAppName,
    isUpdatingAppStatus,
    updatePeripheralAppStatus,
    activeC1Master,
    getCurrentC1Master,
    updateC1MasterEmailIntegrationData,
    checkIsEmailAvailable,
    initiateValidationJob,
    getAppsForAutoIntegration,
    isFirstLoad,
  };
});
