import { DateTypeEnum } from '@/api/client';
import { IDate } from '@/types/date.type';
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  getMonth,
  getQuarter,
  getYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';

export const useDateFormatter = () => {
  const monthsName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const quartersName = ['Q1 (Jan - Mar)', 'Q2 (Apr - Jun)', 'Q3 (Jul - Sep)', 'Q4 (Oct - Dec)'];

  function formatRenewalDate(renewalDate: number | undefined) {
    if (!renewalDate) return 0;
    return Math.abs(renewalDate);
  }

  function getDateString(date: Date) {
    return format(date, 'yyyy/MM/dd');
  }

  function formatRenewalDateString(renewalDate: number | undefined) {
    if (!renewalDate) return 'renewal_days';
    if (renewalDate < 0) return 'expired_days';
    else return 'renewal_days';
  }

  function getIDateDayObj(dayDate: Date) {
    const startDate = startOfDay(dayDate);
    const endDate = endOfDay(dayDate);
    const text = format(startDate, 'yyyy/MM/dd');
    return {
      type: DateTypeEnum.Day,
      isDisabled: false,
      startDate,
      endDate,
      text,
    } as IDate;
  }

  function getIDateWeekObj(weekDate: Date) {
    const weekStartDate = getStartOfWeekDate(weekDate);
    const weekEndDate = getEndOfWeekDate(weekDate);
    const weekNo = iso8601_week_no(weekDate);
    const year = getYear(weekDate);

    return {
      text: `W${weekNo} (${format(weekStartDate, 'dd-MMM')} - ${format(weekEndDate, 'dd-MMM')}) ${year}`,
      type: DateTypeEnum.Week,
      startDate: weekStartDate,
      endDate: weekEndDate,
      isDisabled: false,
      itemNo: weekNo,
    } as IDate;
  }

  function getIDateMonthObj(selectedDate: Date) {
    const monthStartDate = startOfMonth(selectedDate);
    const monthEndDate = endOfMonth(selectedDate);
    const monthNo = getMonth(selectedDate);
    const year = getYear(selectedDate);
    return {
      type: DateTypeEnum.Month,
      isDisabled: false,
      text: `${monthsName[monthNo]} - ${year}`,
      startDate: monthStartDate,
      endDate: monthEndDate,
      itemNo: monthNo,
    } as IDate;
  }

  function getIDateQuarterObj(selectedDate: Date) {
    const quarterStartDate = startOfQuarter(selectedDate);
    const quarterEndDate = endOfQuarter(selectedDate);
    const quarterNo = getQuarter(selectedDate) - 1;
    const year = getYear(selectedDate);
    return {
      type: DateTypeEnum.Quarter,
      isDisabled: false,
      text: `${quartersName[quarterNo]} - ${year}`,
      startDate: quarterStartDate,
      endDate: quarterEndDate,
      itemNo: quarterNo,
    } as IDate;
  }

  function getIDateYearObj(selectedDate: Date) {
    const startDate = startOfYear(selectedDate);
    const endDate = endOfYear(selectedDate);
    const year = getYear(selectedDate);
    return {
      type: DateTypeEnum.Year,
      isDisabled: false,
      text: `${year}`,
      startDate,
      endDate,
      itemNo: year,
    } as IDate;
  }

  function getStartOfWeekDate(date: Date) {
    return startOfWeek(date, { weekStartsOn: 1 });
  }

  function getEndOfWeekDate(date: Date) {
    return endOfWeek(date, { weekStartsOn: 1 });
  }

  function iso8601_week_no(dt: Date) {
    const tdt = new Date(dt);
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - tdt.getTime()) / 604800000);
  }

  function getWeekNo(dt: Date) {
    return iso8601_week_no(dt);
  }

  function getDateWithTime(emailDateStr: string) {
    return format(new Date(emailDateStr), 'dd MMM yyyy, hh:mm aaa');
  }

  return {
    getDateString,
    formatRenewalDate,
    formatRenewalDateString,
    getIDateDayObj,
    getIDateWeekObj,
    getIDateMonthObj,
    getIDateQuarterObj,
    getIDateYearObj,
    iso8601_week_no,
    getWeekNo,
    getDateWithTime,
  };
};
