/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2SegmentHealthListFilterDto } from '../model/';
// @ts-ignore
import { C2SegmentHealthScore } from '../model/';
// @ts-ignore
import { RoleBaseDashboardOverviewDto } from '../model/';
// @ts-ignore
import { RoleBasePaginatedC2SegmentHealthscoreDto } from '../model/';
/**
 * C2SegmentHealthScoreApi - axios parameter creator
 * @export
 */
export const C2SegmentHealthScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Version 1 - List of c2 health score against filter and sorting with pagination 
         * @param {C2SegmentHealthListFilterDto} c2SegmentHealthListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFilterV1: async (c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'c2SegmentHealthListFilterDto' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFilterV1', 'c2SegmentHealthListFilterDto', c2SegmentHealthListFilterDto)
            const localVarPath = `/api/v1/c2-segment-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2SegmentHealthListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
         * @param {string} segmentViewId 
         * @param {C2SegmentHealthListFilterDto} c2SegmentHealthListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFilterV2: async (segmentViewId: string, c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentViewId' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFilterV2', 'segmentViewId', segmentViewId)
            // verify required parameter 'c2SegmentHealthListFilterDto' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFilterV2', 'c2SegmentHealthListFilterDto', c2SegmentHealthListFilterDto)
            const localVarPath = `/api/v2/c2-segment-health-score/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (segmentViewId !== undefined) {
                localVarQueryParameter['segmentViewId'] = segmentViewId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c2SegmentHealthListFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2-Segment-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} segmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFindAllByDateRange: async (date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', segmentId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFindAllByDateRange', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFindAllByDateRange', 'dateType', dateType)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFindAllByDateRange', 'segmentId', segmentId)
            const localVarPath = `/api/c2-segment-health-score/date-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (segmentId !== undefined) {
                localVarQueryParameter['segment_id'] = segmentId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get C2 Segment Health Score by Date Info and C2 Segment ID.
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} segmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFindBySegmentDateInfo: async (date: string, dateType: string, segmentId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFindBySegmentDateInfo', 'date', date)
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFindBySegmentDateInfo', 'dateType', dateType)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('c2SegmentHealthScoreControllerFindBySegmentDateInfo', 'segmentId', segmentId)
            const localVarPath = `/api/c2-segment-health-score/date-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (segmentId !== undefined) {
                localVarQueryParameter['segment_id'] = segmentId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2SegmentHealthScoreApi - functional programming interface
 * @export
 */
export const C2SegmentHealthScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2SegmentHealthScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Version 1 - List of c2 health score against filter and sorting with pagination 
         * @param {C2SegmentHealthListFilterDto} c2SegmentHealthListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentHealthScoreControllerFilterV1(c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasePaginatedC2SegmentHealthscoreDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentHealthScoreControllerFilterV1(c2SegmentHealthListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
         * @param {string} segmentViewId 
         * @param {C2SegmentHealthListFilterDto} c2SegmentHealthListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentHealthScoreControllerFilterV2(segmentViewId: string, c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasePaginatedC2SegmentHealthscoreDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentHealthScoreControllerFilterV2(segmentViewId, c2SegmentHealthListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2-Segment-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} segmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentHealthScoreControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', segmentId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2SegmentHealthScore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentHealthScoreControllerFindAllByDateRange(date, dateType, segmentId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get C2 Segment Health Score by Date Info and C2 Segment ID.
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} segmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2SegmentHealthScoreControllerFindBySegmentDateInfo(date: string, dateType: string, segmentId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseDashboardOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2SegmentHealthScoreControllerFindBySegmentDateInfo(date, dateType, segmentId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2SegmentHealthScoreApi - factory interface
 * @export
 */
export const C2SegmentHealthScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2SegmentHealthScoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Version 1 - List of c2 health score against filter and sorting with pagination 
         * @param {C2SegmentHealthListFilterDto} c2SegmentHealthListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFilterV1(c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasePaginatedC2SegmentHealthscoreDto> {
            return localVarFp.c2SegmentHealthScoreControllerFilterV1(c2SegmentHealthListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
         * @param {string} segmentViewId 
         * @param {C2SegmentHealthListFilterDto} c2SegmentHealthListFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFilterV2(segmentViewId: string, c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasePaginatedC2SegmentHealthscoreDto> {
            return localVarFp.c2SegmentHealthScoreControllerFilterV2(segmentViewId, c2SegmentHealthListFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2-Segment-Health-Score by date range
         * @param {string} date 
         * @param {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'} dateType 
         * @param {string} segmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFindAllByDateRange(date: string, dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week', segmentId: string, xTenantId?: string, options?: any): AxiosPromise<Array<C2SegmentHealthScore>> {
            return localVarFp.c2SegmentHealthScoreControllerFindAllByDateRange(date, dateType, segmentId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get C2 Segment Health Score by Date Info and C2 Segment ID.
         * @param {string} date 
         * @param {string} dateType 
         * @param {string} segmentId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2SegmentHealthScoreControllerFindBySegmentDateInfo(date: string, dateType: string, segmentId: string, xTenantId?: string, options?: any): AxiosPromise<RoleBaseDashboardOverviewDto> {
            return localVarFp.c2SegmentHealthScoreControllerFindBySegmentDateInfo(date, dateType, segmentId, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2SegmentHealthScoreControllerFilterV1 operation in C2SegmentHealthScoreApi.
 * @export
 * @interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1Request
 */
export interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1Request {
    /**
     * 
     * @type {C2SegmentHealthListFilterDto}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1
     */
    readonly after?: string
}

/**
 * Request parameters for c2SegmentHealthScoreControllerFilterV2 operation in C2SegmentHealthScoreApi.
 * @export
 * @interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2Request
 */
export interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2Request {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly segmentViewId: string

    /**
     * 
     * @type {C2SegmentHealthListFilterDto}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly c2SegmentHealthListFilterDto: C2SegmentHealthListFilterDto

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for c2SegmentHealthScoreControllerFindAllByDateRange operation in C2SegmentHealthScoreApi.
 * @export
 * @interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRangeRequest
 */
export interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRangeRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRange
     */
    readonly date: string

    /**
     * 
     * @type {'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRange
     */
    readonly dateType: 'Day' | 'Quarter' | 'Year' | 'Month' | 'Week'

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRange
     */
    readonly segmentId: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRange
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2SegmentHealthScoreControllerFindBySegmentDateInfo operation in C2SegmentHealthScoreApi.
 * @export
 * @interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfoRequest
 */
export interface C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfo
     */
    readonly date: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfo
     */
    readonly dateType: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfo
     */
    readonly segmentId: string

    /**
     * 
     * @type {string}
     * @memberof C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfo
     */
    readonly xTenantId?: string
}

/**
 * C2SegmentHealthScoreApi - object-oriented interface
 * @export
 * @class C2SegmentHealthScoreApi
 * @extends {BaseAPI}
 */
export class C2SegmentHealthScoreApi extends BaseAPI {
    /**
     * 
     * @summary Version 1 - List of c2 health score against filter and sorting with pagination 
     * @param {C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentHealthScoreApi
     */
    public c2SegmentHealthScoreControllerFilterV1(requestParameters: C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV1Request, options?: AxiosRequestConfig) {
        return C2SegmentHealthScoreApiFp(this.configuration).c2SegmentHealthScoreControllerFilterV1(requestParameters.c2SegmentHealthListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Version 2 - List of c2 health score against filter and sorting with pagination 
     * @param {C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentHealthScoreApi
     */
    public c2SegmentHealthScoreControllerFilterV2(requestParameters: C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFilterV2Request, options?: AxiosRequestConfig) {
        return C2SegmentHealthScoreApiFp(this.configuration).c2SegmentHealthScoreControllerFilterV2(requestParameters.segmentViewId, requestParameters.c2SegmentHealthListFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2-Segment-Health-Score by date range
     * @param {C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentHealthScoreApi
     */
    public c2SegmentHealthScoreControllerFindAllByDateRange(requestParameters: C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindAllByDateRangeRequest, options?: AxiosRequestConfig) {
        return C2SegmentHealthScoreApiFp(this.configuration).c2SegmentHealthScoreControllerFindAllByDateRange(requestParameters.date, requestParameters.dateType, requestParameters.segmentId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get C2 Segment Health Score by Date Info and C2 Segment ID.
     * @param {C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2SegmentHealthScoreApi
     */
    public c2SegmentHealthScoreControllerFindBySegmentDateInfo(requestParameters: C2SegmentHealthScoreApiC2SegmentHealthScoreControllerFindBySegmentDateInfoRequest, options?: AxiosRequestConfig) {
        return C2SegmentHealthScoreApiFp(this.configuration).c2SegmentHealthScoreControllerFindBySegmentDateInfo(requestParameters.date, requestParameters.dateType, requestParameters.segmentId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
