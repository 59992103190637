import { u1Api } from '@/api';
import { C1DepartmentEnum, ColumnConfig, Preference, U1 } from '@/api/client';
import { MessageSchema } from '@/lib/i18n';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAccountStore } from './account';
import { useC1DepartmentStore } from './c1Department';

export const useU1Store = defineStore('u1', () => {
  const { t } = useI18n<{ message: MessageSchema }>({
    useScope: 'global',
  });
  const accountStore = useAccountStore();

  const declaredC1Departments = [
    C1DepartmentEnum.CustomerSuccess,
    C1DepartmentEnum.TechAndDevelopment,
    C1DepartmentEnum.Sales,
    C1DepartmentEnum.Finance,
    C1DepartmentEnum.TrainingAndDevelopment,
  ];

  const isLoading = ref(false);
  // const u1List = ref<U1[]>([]);
  const allU1List = ref<U1[]>([]);
  const isLoadingC2Ids = ref(false);
  const u1C2Ids = ref<string[]>([]);
  const u1WithReportees = ref<U1[]>([]);

  const c1DepartmentsStore = useC1DepartmentStore();

  const c1DepartmentCategory = ref<C1DepartmentEnum | undefined>(undefined);

  const u1ListMe = computed(() => {
    return u1List.value.map((u1) => {
      if (u1.id === accountStore.activeU1Id) {
        return {
          ...u1,
          name: t('me'),
        };
      }
      return u1;
    });
  });

  const currentU1Info = computed(() => {
    return u1List.value.find((x) => x.id === accountStore.activeU1Id);
  });

  const u1List = computed(() => {
    return allU1List.value.filter((v) => v?.deleted_at == null) || [];
  });

  // const fetchU1List = async () => {
  //   isLoading.value = true;

  //   try {
  //     const { data } = await u1Api.u1ControllerFindAll();
  //     u1List.value = data.map((x) => {
  //       return {
  //         ...x,
  //         preference: x.preference === null ? ({ customer_columns: [] as ColumnConfig[] } as Preference) : x.preference,
  //       };
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     isLoading.value = false;
  //   }
  // };

  const fetchU1List = async () => {
    isLoading.value = true;

    try {
      const { data } = await u1Api.u1ControllerFindAllAlongWithDeleted();
      allU1List.value = data.map((x) => {
        return {
          ...x,
          preference: x.preference === null ? ({ customer_columns: [] as ColumnConfig[] } as Preference) : x.preference,
        };
      });
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  async function fetchC2IdsForU1() {
    u1C2Ids.value = [];
    try {
      isLoadingC2Ids.value = true;
      const { data } = await u1Api.u1ControllerFindAllC2Ids();
      u1C2Ids.value = data;
    } catch (e) {
      console.error('c2IdsForU1', e);
    } finally {
      isLoadingC2Ids.value = false;
    }
  }

  const u1ListInviteDepartmentType = computed(() => {
    if (c1DepartmentCategory.value) {
      if (c1DepartmentCategory.value === C1DepartmentEnum.Others) {
        const declaredC1DeptNames = declaredC1Departments.map((x) => x as string);

        const c1OtherDeptIds = c1DepartmentsStore.c1Departments
          .filter((x) => !declaredC1DeptNames.includes(x.name))
          .map((v) => v.id);

        return u1List.value.filter(
          (v) => v.u1_master_id !== accountStore.account?.id && c1OtherDeptIds.includes(v.c1_department_id),
        );
      } else {
        const c1Dept = c1DepartmentsStore.c1Departments.find((v) => v.name === (c1DepartmentCategory.value as string));
        return u1List.value.filter(
          (v) => v.u1_master_id !== accountStore.account?.id && v.c1_department_id === c1Dept?.id,
        );
      }
    } else {
      return [] as U1[];
    }
  });

  async function deleteInvitedU1(invitedU1Id: string) {
    try {
      const { status } = await u1Api.u1ControllerRemoveInvitedU1({ id: invitedU1Id });
      if (status === 200) {
        fetchU1List();
      }
    } catch (e) {
      console.log('Failed to delete invited user');
    }
  }

  async function fetchU1WithReportees(u1Id: string) {
    isLoading.value = true;
    try {
      const { data } = await u1Api.u1ControllerFetchU1WithReportees({
        id: u1Id,
      });

      u1WithReportees.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  }

  const u1MeWithReportees = computed(() => {
    return u1WithReportees.value.map((u1) => {
      if (u1.id === accountStore.activeU1Id) {
        return {
          ...u1,
          name: t('me'),
        };
      }
      return u1;
    });
  });

  return {
    isLoading,
    u1List,
    u1ListMe,
    allU1List,
    fetchU1List,
    isLoadingC2Ids,
    u1C2Ids,
    fetchC2IdsForU1,
    u1ListInviteDepartmentType,
    deleteInvitedU1,
    c1DepartmentCategory,
    currentU1Info,
    fetchU1WithReportees,
    u1WithReportees,
    u1MeWithReportees,
  };
});
